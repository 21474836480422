import config from '../utils/config';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { AuthUser, DraftUser, UserInfos } from 'src/models/User';
import { withAuthHeader } from 'src/services/auth-headers';

export const accountApi = createApi({
  reducerPath: 'accountApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.apiEndpoint}/api/accounts`,
    prepareHeaders: withAuthHeader,
  }),
  tagTypes: ['User'],
  endpoints: (builder) => ({
    getUserInfos: builder.query<UserInfos, string>({
      query: (userId) => ({
        url: `${userId}/infos`,
      }),
      providesTags: () => ['User'],
    }),
    signUp: builder.mutation<DraftUser, DraftUser>({
      query: (draftUser) => ({
        url: 'sign-up',
        method: 'POST',
        body: draftUser,
      }),
      transformResponse: (baseQueryReturnValue, meta, draftUser) => draftUser,
    }),
    checkEmail: builder.mutation<void, string>({
      query: (tokenId) => ({
        url: `check-email?tokenId=${tokenId}`,
        method: 'POST',
      }),
    }),
    signIn: builder.mutation<AuthUser, { email: string; password: string }>({
      query: ({ email, password }) => ({
        url: 'sign-in',
        method: 'POST',
        body: { email, password },
      }),
      transformResponse: (result: any) => ({
        userId: result.userId,
        accessToken: result.accessToken,
      }),
      invalidatesTags: ['User'],
    }),
    updateUserInfos: builder.mutation<
      void,
      { userId: string; userInfos: UserInfos }
    >({
      query: ({ userId, userInfos }) => ({
        url: `${userId}/infos`,
        method: 'PUT',
        body: userInfos,
      }),
      invalidatesTags: ['User'],
    }),
    updatePassword: builder.mutation<
      void,
      { userId: string; password: string; newPassword: string }
    >({
      query: ({ userId, password, newPassword }) => ({
        url: `${userId}/password`,
        method: 'PUT',
        body: { password, newPassword },
      }),
    }),
    forgotPassword: builder.mutation<string, string>({
      query: (email) => ({
        url: 'forgot-password',
        method: 'POST',
        body: { email },
      }),
      transformResponse: (baseQueryReturnValue, meta, email) => email,
    }),
    resetPassword: builder.mutation<
      void,
      { tokenId: string; newPassword: string }
    >({
      query: ({ tokenId, newPassword }) => ({
        url: `reset-password?tokenId=${tokenId}`,
        method: 'PUT',
        body: { newPassword },
      }),
    }),
  }),
});

export const {
  useGetUserInfosQuery,
  useSignUpMutation,
  useCheckEmailMutation,
  useSignInMutation,
  useUpdateUserInfosMutation,
  useUpdatePasswordMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
} = accountApi;
