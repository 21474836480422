import { Box, Container, Link, Typography } from '@mui/material';
import React from 'react';
import InstagramIcon from '@mui/icons-material/Instagram';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Brand } from 'shared/constants';

function Footer() {
  return (
    <Box
      sx={{
        backgroundColor: 'white',
        paddingY: '0.1rem',
        position: 'fixed',
        bottom: 0,
        borderTop: '1px solid lightgrey',
        width: '100%',
        minHeight: '35px',
        display: 'flex',
        zIndex: 9999,
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: 'center',
        }}
      >
        <Typography
          color="textSecondary"
          variant="subtitle2"
          lineHeight="unset"
        >
          {`©${new Date().getFullYear()}`} {Brand} - Tous droits réservés.
        </Typography>
        <Box sx={{ flexGrow: 1, textAlign: 'center', marginY: '0.5rem' }}>
          <Link href="/conditions-generales-utilisation">
            Conditions Générales d'Utilisation
          </Link>
        </Box>
        <Box>
          <Link
            href="mailto:lu.colras@gmail.com"
            target="_blank"
            sx={{ paddingRight: '1rem' }}
          >
            <MailOutlineIcon />
          </Link>
          <Link
            href="https://www.instagram.com/fishtonspot/?hl=fr"
            target="_blank"
          >
            <InstagramIcon />
          </Link>
        </Box>
      </Container>
    </Box>
  );
}

export default Footer;
