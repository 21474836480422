import React from 'react';
import { Box, CircularProgress, Container } from '@mui/material';
import { H1 } from 'src/components/Styled/Styled';
import { useDocumentTitle } from 'src/hooks/useDocumentTitle';
import { useSignInMutation } from 'src/services/account.service';
import { Brand } from 'shared/constants';
import { useNavigate } from 'react-router-dom';
import AccountSigninForm from 'src/components/Account/AccountSigninForm/AccountSigninForm';

const SigninView = () => {
  const navigate = useNavigate();
  useDocumentTitle('Connectez-vous');

  const [_, { isLoading: isSignInLoading }] = useSignInMutation(); // eslint-disable-line @typescript-eslint/no-unused-vars

  return (
    <Container maxWidth="sm">
      {isSignInLoading ? (
        <Box sx={{ textAlign: 'center', marginY: '10rem' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <H1>Connectez-vous à {Brand}</H1>
          <AccountSigninForm onConnect={() => navigate('/')} />
        </>
      )}
    </Container>
  );
};

export default SigninView;
