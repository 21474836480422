import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Spot } from 'src/models/Spot';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import * as yup from 'yup';
import { useForm } from 'src/hooks/useForm';
import { DepartmentList } from 'shared/models/Department';
import AppCloseButton from 'src/components/AppCloseButton/AppCloseButton';

interface Props {
  spot: Spot;
  open: boolean;
  onSubmit: (name: string, location: string, department: string) => void;
  onClose: () => void;
}

const LocationDialog = ({ spot, open, onSubmit, onClose }: Props) => {
  const [name, setName] = useState(spot.name);
  const [location, setLocation] = useState(spot.location);
  const [department, setDepartment] = useState(spot.department);

  const schema = yup.object().shape({
    name: yup.string().required('Veuillez renseigner un nom.'),
    location: yup.string().required('Veuillez renseigner une commune.'),
    department: yup.string().required('Veuillez renseigner un département.'),
  });

  const { message, validate, messageType } = useForm(schema, {
    name,
    location,
    department,
  });

  const submit = async () => {
    await validate(() => onSubmit(name, location, department));
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      disableScrollLock
    >
      <DialogTitle>
        Localisation
        <AppCloseButton onClose={onClose} />
      </DialogTitle>
      <DialogContent>
        <TextField
          value={name}
          onChange={(event) => setName(event.target.value)}
          autoFocus
          label="Nom"
          type="text"
          fullWidth
          variant="standard"
          margin="normal"
          error={messageType('name') === 'error'}
        />
        <FormHelperText error>{message('name')}</FormHelperText>
        <TextField
          value={location}
          onChange={(event) => setLocation(event.target.value)}
          autoFocus
          label="Commune"
          type="text"
          fullWidth
          variant="standard"
          margin="normal"
          error={messageType('location') === 'error'}
        />
        <FormHelperText error>{message('location')}</FormHelperText>
        <FormControl
          fullWidth
          variant="standard"
          margin="normal"
          error={messageType('department') === 'error'}
        >
          <InputLabel id="department-label">Département</InputLabel>
          <Select
            labelId="department-label"
            id="department-select"
            value={department}
            label="Age"
            onChange={(event) => setDepartment(event.target.value)}
          >
            {DepartmentList.map((department) => (
              <MenuItem value={department.code} key={department.code}>
                {department.code} - {department.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormHelperText error>{message('department')}</FormHelperText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Annuler
        </Button>
        <Button variant="contained" onClick={submit}>
          Enregistrer les modifications
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LocationDialog;
