import React from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Alert,
  AlertTitle,
  Box,
  Card,
  CardContent,
  Container,
  Grid,
} from '@mui/material';
import SpotEquipments from 'src/components/SpotEquipments/SpotEquipments';
import SpotDescription from 'src/components/SpotDescription/SpotDescription';
import OwnerCalendar from 'src/components/Calendar/OwnerCalendar';
import { useAuthentication } from 'src/hooks/useAuthentication';
import SpotFishes from 'src/components/SpotFishes/SpotFishes';
import { useGetSpotQuery } from 'src/services/spot.service';
import SpotFiles from 'src/components/SpotFiles/SpotFiles';
import SpotLocation from 'src/components/SpotLocation/SpotLocation';
import SpotPrice from 'src/components/SpotPrice/SpotPrice';
import { useDocumentTitle } from 'src/hooks/useDocumentTitle';
import SpotPublication from 'src/components/SpotPublication/SpotPublication';
import { Spot } from 'src/models/Spot';
import { useFindSpotFilesQuery } from 'src/services/spotFile.service';
import { useFindSpotPricesQuery } from 'src/services/price.service';
import SpotBooking from 'src/components/SpotBooking/SpotBooking';
import FAQ from 'src/components/FAQ/FAQ';
import SpotRules from 'src/components/SpotRules/SpotRules';

// interface Props {}

const SpotView = () => {
  const { isSpotOwner } = useAuthentication();
  const [searchParams] = useSearchParams();
  const spotId = searchParams.get('id');

  const { data: spot, isFetching } = useGetSpotQuery(spotId!, {
    skip: !spotId,
  });
  const { data: spotFiles } = useFindSpotFilesQuery(spotId!, {
    skip: !spotId,
  });
  const { data: spotPrice } = useFindSpotPricesQuery(spotId!, {
    skip: !spotId,
  });

  useDocumentTitle(spot?.location);

  const isPublishable = (spot: Spot) =>
    (spot.description?.length ?? 0 > 1) &&
    (spotFiles?.length ?? 0 > 1) &&
    !!spotPrice;

  if (!spot) {
    if (!isFetching) {
      return (
        <Container maxWidth="xl">
          <Alert severity="error" sx={{ marginY: 20 }}>
            La page demandée n'existe pas
          </Alert>
        </Container>
      );
    }

    return <></>;
  }

  return (
    <Container maxWidth="xl">
      {isSpotOwner(spot) && !isPublishable(spot) && (
        <Alert severity="success" sx={{ marginTop: '3rem' }}>
          <AlertTitle>Votre annonce est presque prête !</AlertTitle>
          Votre annonce est bien créée. <br />
          Pour pouvoir la valider vous devez ajoutez à minima une photo, une
          description et un tarif.
        </Alert>
      )}
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm={8}>
          <SpotLocation spot={spot} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SpotPublication spot={spot} />
        </Grid>
      </Grid>
      <SpotFiles spot={spot} />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={7} lg={8}>
          <SpotFishes spot={spot} />
          <SpotDescription spot={spot} />
          <SpotEquipments spot={spot} />
          <SpotRules spot={spot} />
          {!isSpotOwner(spot) && <FAQ />}
        </Grid>
        <Grid item xs={12} sm={6} md={5} lg={4}>
          <Box
            sx={{
              position: 'sticky',
              top: '5rem',
              minWidth: '275',
            }}
          >
            {isSpotOwner(spot) && (
              <Card elevation={15} sx={{ marginBottom: '1rem' }}>
                <CardContent>
                  <SpotPrice spot={spot} />
                </CardContent>
              </Card>
            )}
            <Card elevation={15}>
              {isSpotOwner(spot) ? (
                <CardContent>
                  <OwnerCalendar
                    spotId={spot.id}
                    rentalKind={spot.rentalKind}
                  />
                </CardContent>
              ) : (
                <CardContent>
                  <SpotBooking spotId={spot.id} rentalKind={spot.rentalKind} />
                </CardContent>
              )}
            </Card>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SpotView;
