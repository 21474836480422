import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

export interface Props {
  open: boolean;
  onSubmit: () => Promise<void>;
  onClose: () => void;
}

function RemoveDialog({ onClose, onSubmit, open }: Props) {
  return (
    <Dialog onClose={onClose} open={open} scroll="paper" disableScrollLock>
      <DialogTitle>Suppression de l'annonce</DialogTitle>

      <DialogContent>
        Etes-vous sûr de vouloir supprimer cette annonce ?
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Annuler
        </Button>
        <Button variant="contained" onClick={onSubmit}>
          Supprimer
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default RemoveDialog;
