import React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import { useAuthentication } from 'src/hooks/useAuthentication';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import authSlice from 'src/store/reducers/authSlice';
import { AccountCircleOutlined } from '@mui/icons-material';

const AccountMenu = () => {
  const { isAuthenticated, userInfos, hasAnnouncementPermission } =
    useAuthentication();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            {isAuthenticated ? (
              <Avatar sx={{ width: 32, height: 32 }}>
                {userInfos?.userName[0].toUpperCase()}
              </Avatar>
            ) : (
              <AccountCircleOutlined />
            )}
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        disableScrollLock
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {isAuthenticated &&
          hasAnnouncementPermission && [
            <MenuItem
              onClick={() => navigate('/mes-annonces')}
              key="announcement_menu_item"
            >
              Mes annonces
            </MenuItem>,
            <Divider key="divider_menu_item1" />,
          ]}
        {isAuthenticated
          ? [
              <MenuItem
                onClick={() => navigate('/mon-compte')}
                key="account_menu_item"
              >
                <Avatar />
                Mon compte
              </MenuItem>,
              <Divider key="divider_menu_item2" />,
              <MenuItem
                onClick={() => dispatch(authSlice.actions.signoutUser())}
                key="signout_menu_item"
              >
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Me déconnecter
              </MenuItem>,
            ]
          : [
              <MenuItem
                onClick={() => {
                  handleClose();
                  navigate('/inscription');
                }}
                key="signup_menu_item"
              >
                S'inscrire
              </MenuItem>,
              <Divider key="divider°divider_menu_item3" />,
              <MenuItem
                onClick={() => {
                  handleClose();
                  navigate('/connexion');
                }}
                key="signin_menu_item"
              >
                Se connecter
              </MenuItem>,
            ]}
      </Menu>
    </>
  );
};

export default AccountMenu;
