import { useState } from 'react';
import {
  addDays,
  addHours,
  endOfDay,
  endOfMonth,
  isAfter,
  isBefore,
  isEqual,
  startOfDay,
  startOfMonth,
} from 'date-fns';
import { useFindCalendarEventsQuery } from 'src/services/calendar-events.service';
import { CalendarEvent } from 'src/models/CalendarEvent';
import {
  rentalEndTimeShift,
  RentalKind,
  rentalStartTimeShift,
} from 'shared/types/RentalKind';

export const useCalendar = (spotId: string, rentalKind: RentalKind) => {
  const [from, setFrom] = useState(startOfDay(startOfMonth(new Date())));
  const [to, setTo] = useState(endOfDay(endOfMonth(new Date())));

  const { data: calendarEvents } = useFindCalendarEventsQuery(
    { spotId, from, to },
    { refetchOnMountOrArgChange: true }
  );
  const handleMonthChange = (monthDate: Date) => {
    setFrom(startOfDay(startOfMonth(monthDate)));
    setTo(endOfDay(endOfMonth(monthDate)));
  };

  const amEventOfDay = (day: Date): CalendarEvent | undefined =>
    calendarEvents?.find(
      (calendarEvent) =>
        (isBefore(calendarEvent.start, day) ||
          isEqual(calendarEvent.start, day)) &&
        (isEqual(
          calendarEvent.end,
          addHours(day, rentalEndTimeShift(rentalKind))
        ) ||
          isAfter(
            calendarEvent.end,
            addHours(day, rentalEndTimeShift(rentalKind))
          ))
    );

  const pmEventOfDay = (day: Date): CalendarEvent | undefined =>
    calendarEvents?.find(
      (calendarEvent) =>
        (isBefore(
          calendarEvent.start,
          addHours(day, rentalStartTimeShift(rentalKind))
        ) ||
          isEqual(
            calendarEvent.start,
            addHours(day, rentalStartTimeShift(rentalKind))
          )) &&
        (isEqual(calendarEvent.end, addDays(day, 1)) ||
          isAfter(calendarEvent.end, addDays(day, 1)))
    );

  return {
    calendarEvents,
    amEventOfDay,
    pmEventOfDay,
    handleMonthChange,
  };
};
