import * as React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { ChangeEvent } from 'react';
import { userRoleLabel } from 'src/models/User';
import { UserRole } from 'shared/types/UserRole';

interface Props {
  checkboxRole: UserRole;
  userRoles: UserRole[];
  onChange: (e: ChangeEvent<HTMLInputElement>, checkboxRole: UserRole) => void;
}

const AccountRoleCheckbox = ({ checkboxRole, userRoles, onChange }: Props) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={userRoles.includes(checkboxRole)}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChange(e, checkboxRole)
          }
        />
      }
      label={userRoleLabel(checkboxRole)}
    />
  );
};

export default AccountRoleCheckbox;
