import * as React from 'react';
import { FormEvent, useState } from 'react';
import TextField from '@mui/material/TextField';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from '@mui/material';
import * as yup from 'yup';
import { useForm } from 'src/hooks/useForm';
import { DepartmentList } from 'shared/models/Department';
import { SpotKind } from 'shared/types/SpotKind';
import Button from '@mui/material/Button';
import { useCreateSpotMutation } from 'src/services/spot.service';
import { useAuthentication } from 'src/hooks/useAuthentication';
import { useNavigate } from 'react-router-dom';
import { RentalKind } from 'shared/types/RentalKind';

interface Props {
  onCancel: () => void;
  onNavigate: () => void;
}

const NewSpotForm = ({ onCancel, onNavigate }: Props) => {
  const navigate = useNavigate();
  const { hasRole, isAdmin } = useAuthentication();
  const [kind, setKind] = useState<SpotKind | null>(
    hasRole('PondOwner') && !hasRole('Guide')
      ? 'Pond'
      : hasRole('Guide') && !hasRole('PondOwner')
      ? 'Guide'
      : null
  );
  const [rentalKind, setRentalKind] = useState<RentalKind | null>(
    hasRole('Guide') && !hasRole('PondOwner') ? 'Day' : null
  );
  const [name, setName] = useState<string | null>(null);
  const [location, setLocation] = useState<string | null>(null);
  const [department, setDepartment] = useState<string | null>(null);

  const schema = yup.object().shape({
    kind: yup
      .string()
      .nullable()
      .required("Veuillez renseigner un type d'annonce."),
    rentalKind: yup
      .string()
      .nullable()
      .required('Veuillez renseigner un type de location.'),
    name: yup.string().nullable().required('Veuillez renseigner un nom.'),
    location: yup
      .string()
      .nullable()
      .required('Veuillez renseigner une commune.'),
    department: yup
      .string()
      .nullable()
      .required('Veuillez renseigner un département.'),
  });

  const { message, validate, messageType } = useForm(schema, {
    kind,
    rentalKind,
    name,
    location,
    department,
  });

  const [createSpot] = useCreateSpotMutation();
  const submitSpot = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    await validate(() => {
      createSpot({
        kind: kind!,
        rentalKind: rentalKind!,
        name: name!,
        location: location!,
        department: department!,
      })
        .unwrap()
        .then((spot) => {
          navigate(`/annonces?id=${spot.id}`);
          onNavigate();
        });
    });
  };

  return (
    <Box component="form" onSubmit={submitSpot}>
      {((hasRole('PondOwner') && hasRole('Guide')) || isAdmin) && (
        <Box paddingTop="2rem">
          <FormLabel>Quel type d'annonce souhaitez-vous publier ?</FormLabel>
          <RadioGroup
            value={kind}
            onChange={(_, value) => {
              setRentalKind(value === 'Guide' ? 'Day' : null);
              setKind(value as SpotKind);
            }}
          >
            <FormControlLabel
              value="Pond"
              control={<Radio />}
              label="Lieu de pêche"
            />
            <FormControlLabel
              value="Guide"
              control={<Radio />}
              label="Guidage"
            />
          </RadioGroup>
        </Box>
      )}
      <FormHelperText error>{message('kind')}</FormHelperText>
      {kind === 'Pond' && (
        <Box paddingTop="2rem">
          <FormLabel>Quel type de location proposez-vous ?</FormLabel>
          <RadioGroup
            value={rentalKind}
            onChange={(_, value) => setRentalKind(value as RentalKind)}
          >
            <FormControlLabel
              value="Day"
              control={<Radio />}
              label={
                <>
                  À la <b>journée</b> 
                  <Typography variant="caption">
                    (Arrivée le matin, départ l'après-midi)
                  </Typography>
                </>
              }
            />
            <FormControlLabel
              value="Night"
              control={<Radio />}
              label={
                <>
                  À la <b>nuitée</b> 
                  <Typography variant="caption">
                    (Arrivée l'après-midi, départ le matin)
                  </Typography>
                </>
              }
            />
          </RadioGroup>
          <FormHelperText error>{message('rentalKind')}</FormHelperText>
        </Box>
      )}
      <TextField
        value={name}
        onChange={(event) => setName(event.target.value)}
        autoFocus
        label="Nom"
        type="text"
        fullWidth
        variant="standard"
        margin="normal"
        error={messageType('name') === 'error'}
      />
      <FormHelperText error>{message('name')}</FormHelperText>
      <TextField
        value={location}
        onChange={(event) => setLocation(event.target.value)}
        autoFocus
        label="Commune"
        type="text"
        fullWidth
        variant="standard"
        margin="normal"
        error={messageType('location') === 'error'}
      />
      <FormHelperText error>{message('location')}</FormHelperText>
      <FormControl
        fullWidth
        variant="standard"
        margin="normal"
        error={messageType('department') === 'error'}
      >
        <InputLabel id="department-label">Département</InputLabel>
        <Select
          labelId="department-label"
          id="department-select"
          value={department}
          label="Age"
          onChange={(event) => setDepartment(event.target.value)}
        >
          {DepartmentList.map((department) => (
            <MenuItem value={department.code} key={department.code}>
              {department.code} - {department.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormHelperText error>{message('department')}</FormHelperText>
      <Button variant="outlined" onClick={onCancel}>
        Annuler
      </Button>
      <Button variant="contained" type="submit" sx={{ marginLeft: '1rem' }}>
        Créer l'annonce
      </Button>
    </Box>
  );
};

export default NewSpotForm;
