import React, { useRef, useState } from 'react';
import { DateRange, DateRangeCalendar } from '@mui/x-date-pickers-pro';
import { CalendarEvent } from 'src/models/CalendarEvent';
import CalendarEventPopover from './CalendarEventPopover';
import { H2 } from '../Styled/Styled';
import { useCalendar } from 'src/hooks/useCalendar';
import CalendarDay from 'src/components/Calendar/CalendarDay';
import { addHours } from 'date-fns';
import {
  rentalEndTimeShift,
  RentalKind,
  rentalStartTimeShift,
} from 'shared/types/RentalKind';

interface Props {
  spotId: string;
  rentalKind: RentalKind;
}

const EmptyDateRange: DateRange<Date> = [null, null];

const OwnerCalendar = ({ spotId, rentalKind }: Props) => {
  const { handleMonthChange, amEventOfDay, pmEventOfDay } = useCalendar(
    spotId,
    rentalKind
  );
  const [dateRange, setDateRange] = useState<DateRange<Date>>(EmptyDateRange);
  const [calendarEvent, setCalendarEvent] = useState<
    CalendarEvent | undefined
  >();

  const cancelDateRange = () => {
    setDateRange(EmptyDateRange);
    setCalendarEvent(undefined);
  };

  const dayRef = useRef<HTMLElement | null>(null);

  return (
    <>
      <H2>Agenda</H2>
      <DateRangeCalendar
        value={dateRange}
        calendars={1}
        onChange={(newDateRange) => {
          if (rentalKind === 'Night' && newDateRange[0] === newDateRange[1]) {
            cancelDateRange();
          } else {
            setDateRange(newDateRange);
          }
        }}
        onMonthChange={handleMonthChange}
        disableAutoMonthSwitching
        disableHighlightToday
        slots={{ day: CalendarDay }}
        slotProps={{
          day: {
            amEventOfDay,
            pmEventOfDay,
            onDayClick: (
              event: React.MouseEvent<HTMLButtonElement>,
              calendarEvent?: CalendarEvent
            ) => {
              dayRef.current = event.target as HTMLElement;
              if (calendarEvent) {
                setDateRange([calendarEvent.start, calendarEvent.end]);
                setCalendarEvent(calendarEvent);
              }
            },
          } as any,
        }}
        sx={{ justifyContent: 'center' }}
      />
      {dateRange[0] && dateRange[1] && dayRef.current && (
        <CalendarEventPopover
          event={
            calendarEvent ?? {
              spotId: spotId,
              start: addHours(dateRange[0], rentalStartTimeShift(rentalKind)),
              end: addHours(dateRange[1], rentalEndTimeShift(rentalKind)),
            }
          }
          onClose={cancelDateRange}
          anchorEl={dayRef.current}
        />
      )}
    </>
  );
};

export default OwnerCalendar;
