import React, { useState } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  IconButton,
} from '@mui/material';
import { useDocumentTitle } from 'src/hooks/useDocumentTitle';
import { H1 } from 'src/components/Styled/Styled';
import { Brand } from 'shared/constants';
import { useAuthentication } from 'src/hooks/useAuthentication';
import { pluralize } from 'src/utils/stringUtils';
import { Edit } from '@mui/icons-material';
import AppWithIcon from 'src/components/AppWithIcon/AppWithIcon';
import AppDialog from 'src/components/AppDialog/AppDialog';
import AccountInfosForm from 'src/components/Account/AccountInfosForm/AccountInfosForm';
import { userRoleLabel } from 'src/models/User';
import AccountPasswordForm from 'src/components/Account/AccountPasswordForm/AccountPasswordForm';

const AccountView = () => {
  useDocumentTitle('Votre compte');
  const { userInfos, userId, isAdmin } = useAuthentication();
  const [accountInfosDialogOpen, setAccountInfosDialogOpen] = useState(false);
  const [accountPasswordDialogOpen, setAccountPasswordDialogOpen] =
    useState(false);

  if (!userId || !userInfos) {
    return <></>;
  }

  return (
    <Container maxWidth="xl">
      <H1>Compte {Brand}</H1>
      <Grid container spacing={1} sx={{ paddingY: '2rem' }}>
        <Grid item xs={12} sm={6} md={4}>
          <Card elevation={3}>
            <CardHeader
              title={
                <AppWithIcon
                  icon={
                    isAdmin ? (
                      <></>
                    ) : (
                      <IconButton
                        color="primary"
                        onClick={() => setAccountInfosDialogOpen(true)}
                      >
                        <Edit />
                      </IconButton>
                    )
                  }
                  iconPosition="right"
                >
                  Informations personnelles
                </AppWithIcon>
              }
            />
            <AppDialog
              title="Modification de vos informations personnelles"
              open={accountInfosDialogOpen}
              onClose={() => setAccountInfosDialogOpen(false)}
              content={
                <AccountInfosForm
                  userId={userId}
                  userInfos={userInfos}
                  onSubmit={() => setAccountInfosDialogOpen(false)}
                />
              }
            />
            <Divider />
            <CardContent>
              Email : <b>{userInfos.email}</b>
            </CardContent>
            <CardContent>Nom d'utilisateur : {userInfos.userName}</CardContent>
            <CardContent>
              {pluralize(userInfos.roles.length)('Profil')} : 
              {userInfos.roles.map((role) => userRoleLabel(role)).join(', ')}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card elevation={3}>
            <CardHeader
              title={
                <AppWithIcon
                  icon={
                    <IconButton
                      color="primary"
                      onClick={() => setAccountPasswordDialogOpen(true)}
                    >
                      <Edit />
                    </IconButton>
                  }
                  iconPosition="right"
                >
                  Mot de passe
                </AppWithIcon>
              }
            />
            <AppDialog
              title="Modification de votre mot de passe"
              open={accountPasswordDialogOpen}
              onClose={() => setAccountPasswordDialogOpen(false)}
              content={
                <AccountPasswordForm
                  userId={userId}
                  onSubmit={() => setAccountPasswordDialogOpen(false)}
                />
              }
            />
            <Divider />
            <CardContent>**********</CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AccountView;
