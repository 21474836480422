import React from 'react';
import {
  DateRangePickerDay,
  DateRangePickerDayProps,
} from '@mui/x-date-pickers-pro';
import { CalendarEvent } from 'src/models/CalendarEvent';
import { theme } from 'src/theme';
import { styled, Tooltip } from '@mui/material';

const CalendarDay = (
  props: DateRangePickerDayProps<Date> & {
    amEventOfDay?: (day: Date) => CalendarEvent | undefined;
    pmEventOfDay?: (day: Date) => CalendarEvent | undefined;
    onDayClick?: (
      event: React.MouseEvent<HTMLButtonElement>,
      calendarEvent?: CalendarEvent
    ) => void;
  }
) => {
  const { day, amEventOfDay, pmEventOfDay, onDayClick, ...other } = props;

  const amEvent = amEventOfDay?.(day);
  const pmEvent = pmEventOfDay?.(day);
  const t = {
    ...other,
    onClick: (event: any) => onDayClick?.(event),
  };

  return pmEvent && amEvent && amEvent.id === pmEvent.id && onDayClick ? (
    <div
      onClick={(event) => {
        onDayClick?.(event as any, pmEvent);
      }}
    >
      <CustomDateRangePickerDay
        {...other}
        day={day}
        disabled
        amEvent={amEvent}
        pmEvent={pmEvent}
      />
    </div>
  ) : pmEvent && amEvent && amEvent.id === pmEvent.id ? (
    <Tooltip
      title="Indisponible"
      placement="top"
      followCursor={true}
      sx={{ cursor: 'default' }}
    >
      <div>
        <CustomDateRangePickerDay
          {...other}
          day={day}
          disabled
          hasTooltip
          amEvent={amEvent}
          pmEvent={pmEvent}
        />
      </div>
    </Tooltip>
  ) : pmEvent || amEvent ? (
    <div>
      <CustomDateRangePickerDay
        {...other}
        day={day}
        hasTooltip
        amEvent={amEvent}
        pmEvent={pmEvent}
      />
    </div>
  ) : (
    <DateRangePickerDay day={day} {...t} />
  );
};

interface Props extends DateRangePickerDayProps<Date> {
  amEvent?: CalendarEvent;
  pmEvent?: CalendarEvent;
  hasTooltip?: boolean;
}

const eventColor = (event?: CalendarEvent) => {
  if (event) {
    switch (event.kind) {
      case 'Unavailable':
        return theme.palette.grey['700'];
      case 'Rental':
        return theme.palette.primary.light;
      case 'Other':
        return theme.palette.grey['500'];
    }
  }
  return theme.palette.secondary.main;
};

const CustomDateRangePickerDay = styled(DateRangePickerDay, {
  shouldForwardProp: (prop) =>
    prop !== 'pmEvent' && prop !== 'amEvent' && prop !== 'hasTooltip',
})<Props>(({ amEvent, pmEvent, hasTooltip }) => ({
  ...{
    div: {
      borderTopColor: eventColor(amEvent),
      borderLeftColor: eventColor(amEvent),
      borderRightColor: eventColor(pmEvent),
      borderBottomColor: eventColor(pmEvent),
      cursor: hasTooltip ? 'default' : 'pointer',
    },
    button: {
      borderRadius: 0,
      // '&.Mui-disabled': {
      background: `linear-gradient(to right bottom, ${eventColor(
        amEvent
      )} 50%, ${eventColor(pmEvent)} 50%)`,
      color: 'white !important',
      // },
    },
  },
})) as React.ComponentType<Props>;

export default CalendarDay;
