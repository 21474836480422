import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import WaterIcon from '@mui/icons-material/Water';
import AppWithIcon from '../AppWithIcon/AppWithIcon';
import SpotAnnouncementCard, {
  SpotAnnouncementExtraInfos,
} from '../SpotAnnouncementCard/SpotAnnouncementCard';
import { SpotKind } from 'shared/types/SpotKind';
import { useAuthentication } from 'src/hooks/useAuthentication';
import { useFindSpotAnnouncementsQuery } from 'src/services/spot.service';

interface Props {
  spotKind: SpotKind;
  published?: boolean;
  title?: string;
  extraInfos?: SpotAnnouncementExtraInfos[];
}

const SpotAnnouncementList = ({
  spotKind,
  title,
  published,
  ...other
}: Props) => {
  const { userId, isAdmin } = useAuthentication();
  const { data: spotAnnouncements } = useFindSpotAnnouncementsQuery({
    userId: published || isAdmin ? undefined : userId,
    spotKind,
    published,
  });

  return (
    <Box marginY="2rem">
      {title && (
        <Typography
          gutterBottom
          variant="h2"
          sx={{
            fontSize: '1.55rem',
            fontWeight: 400,
            color: '#5C5C5C',
            paddingBottom: '1.5rem',
          }}
        >
          <AppWithIcon icon={<WaterIcon />}>{title}</AppWithIcon>
        </Typography>
      )}

      <Grid
        container
        columns={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 6 }}
        spacing={{ xs: 3, md: 4, xl: 5 }}
      >
        {spotAnnouncements?.map((spotAnnouncement) => (
          <Grid item xs={1} key={spotAnnouncement.id}>
            <SpotAnnouncementCard
              spotAnnouncement={spotAnnouncement}
              {...other}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default SpotAnnouncementList;
