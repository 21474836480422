import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Spot } from 'src/models/Spot';
import {
  ArrowForwardIos,
  Block,
  CheckCircle,
  Checklist,
  Edit,
} from '@mui/icons-material';
import { theme } from 'src/theme';
import RulesDialog from './RulesDialog';
import { Requirement } from 'shared/types/Requirement';
import { useAuthentication } from 'src/hooks/useAuthentication';
import { H2 } from '../Styled/Styled';
import { useUpdateSpotRulesMutation } from 'src/services/spot.service';
import { Prohibition } from 'shared/types/Prohibition';
import { Permission } from 'shared/types/Permission';

interface Props {
  spot: Spot;
}

const SpotRules = ({ spot }: Props) => {
  const { isSpotOwner } = useAuthentication();
  const collapsableLength = useMediaQuery(theme.breakpoints.up('md')) ? 10 : 5;
  const [collapsed, setCollapsed] = useState(
    spot.requirements.length > collapsableLength
  );
  const [dialogOpen, setDialogOpen] = useState(false);

  const [updateRules] = useUpdateSpotRulesMutation();
  const submitRules = async (
    requirements: Requirement[],
    prohibitions: Prohibition[],
    permissions: Permission[]
  ) => {
    await updateRules({
      spotId: spot.id,
      requirements,
      prohibitions,
      permissions,
    });
    setDialogOpen(false);
  };

  return (
    <Card>
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          <H2>Réglement</H2>
          {isSpotOwner(spot) && (
            <>
              <Button
                variant="contained"
                startIcon={<Edit />}
                onClick={() => setDialogOpen(true)}
              >
                Modifier
              </Button>

              <RulesDialog
                spot={spot}
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                onSubmit={submitRules}
              />
            </>
          )}
        </Box>

        <Divider sx={{ marginY: '1rem' }} />
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            {spot.requirements.map((requirement, index) => (
              <Typography
                component="div"
                key={'requirement_' + index}
                sx={{ display: 'flex' }}
              >
                <Checklist />
                <div
                  style={{
                    maxWidth: 'calc(100% - 35px)',
                    marginLeft: '0.5rem',
                  }}
                >
                  {requirement}
                </div>
              </Typography>
            ))}
          </Grid>
          <Grid item xs={12} md={4}>
            {spot.permissions.map((permission, index) => (
              <Typography
                component="div"
                key={'permission_' + index}
                sx={{ display: 'flex' }}
              >
                <CheckCircle color="success" />
                <div
                  style={{
                    maxWidth: 'calc(100% - 35px)',
                    marginLeft: '0.5rem',
                  }}
                >
                  {permission}
                </div>
              </Typography>
            ))}
          </Grid>
          <Grid item xs={12} md={4}>
            {spot.prohibitions.map((prohibition, index) => (
              <Typography
                component="div"
                key={'prohibition_' + index}
                sx={{ display: 'flex' }}
              >
                <Block color="error" />
                <div
                  style={{
                    maxWidth: 'calc(100% - 35px)',
                    marginLeft: '0.5rem',
                  }}
                >
                  {prohibition}
                </div>
              </Typography>
            ))}
          </Grid>
        </Grid>
        {collapsed && spot.requirements.length >= collapsableLength && (
          <Button
            variant="outlined"
            endIcon={<ArrowForwardIos fontSize="small" />}
            onClick={() => setCollapsed(false)}
            sx={{ marginTop: '1rem' }}
          >
            Voir tous les équipements
          </Button>
        )}
      </CardContent>
    </Card>
  );
};

export default SpotRules;
