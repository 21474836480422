import { styled } from '@mui/system';

export const H1 = styled('h1')(({ theme }) =>
  theme.unstable_sx({
    fontWeight: 'bold',
    fontSize: { xs: '1.5rem', sm: '2rem' },
    paddingTop: '1rem',
    marginBottom: 0,
  })
);

export const H2 = styled('h2')(({ theme }) =>
  theme.unstable_sx({
    fontSize: { xs: '1rem', sm: '1rem' },
    margin: 0,
    flexGrow: 1,
  })
);
