export const getURLSearchParams = (params: Object) => {
  const searchParams = new URLSearchParams(
    Object.entries(params).filter(
      ([_, v]) => v != null && !(v instanceof Array)
    )
  );

  Object.entries(params)
    .filter(([_, v]) => v instanceof Array && v.length)
    .forEach(([k, v]) => {
      searchParams.set(k, v[0]);
      (v as Array<string>).forEach((_) => searchParams.append(k, _));
    });

  return searchParams;
};
