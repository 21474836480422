import React from 'react';
import {
  Autocomplete,
  createFilterOptions,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
} from '@mui/material';
import { Clear } from '@mui/icons-material';

export interface Props {
  items: string[];
  defaultItems: string[];
  inputLabel: string;
  onChange: (items: string[]) => void;
}

function AppSelectList({ items, defaultItems, inputLabel, onChange }: Props) {
  const [newItem, setNewItem] = React.useState<string | null>(null);

  const filter = createFilterOptions<string>();

  const removeItem = (index: number) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    onChange(newItems);
  };

  const addItem = (item: string | null) => {
    if (item?.length) {
      onChange([...items, item.replace('Ajouter ', '')]);
      setNewItem(item);
      setTimeout(() => setNewItem(''));
    }
  };

  return (
    <>
      <Autocomplete
        value={newItem}
        onChange={(_, newItem) => {
          addItem(newItem);
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          const { inputValue } = params;
          if (
            inputValue !== '' &&
            !options.some((option) => inputValue === option)
          ) {
            filtered.push(`Ajouter ${inputValue}`);
          }
          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        clearOnEscape
        handleHomeEndKeys
        options={defaultItems.filter((_) => !items.includes(_))}
        getOptionLabel={(option) => option}
        renderOption={(props, option) => <li {...props}>{option}</li>}
        freeSolo
        renderInput={(params) => <TextField {...params} label={inputLabel} />}
      />
      <List>
        {items.map((item, index) => (
          <ListItem
            key={'item_' + index}
            secondaryAction={
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => removeItem(index)}
              >
                <Clear />
              </IconButton>
            }
          >
            <ListItemText>{item}</ListItemText>
          </ListItem>
        ))}
      </List>
    </>
  );
}

export default AppSelectList;
