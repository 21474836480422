import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  Popover,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { theme } from 'src/theme';
import {
  CalendarEvent,
  DraftCalendarEvent,
  eventKindLabel,
  isCalendarEvent,
} from 'src/models/CalendarEvent';
import * as yup from 'yup';
import { useForm } from 'src/hooks/useForm';
import {
  useCreateCalendarEventMutation,
  useDeleteCalendarEventMutation,
  useUpdateCalendarEventMutation,
} from 'src/services/calendar-events.service';
import { Close, Delete, Edit } from '@mui/icons-material';
import AppWithIcon from '../AppWithIcon/AppWithIcon';
import { EventKind } from 'shared/types/EventKind';
import AppError from 'src/components/AppError/AppError';

interface Props {
  event: CalendarEvent | Omit<DraftCalendarEvent, 'kind'>;
  onClose: () => void;
  anchorEl: HTMLElement;
}

const CalendarEventPopover = ({ event, onClose, anchorEl }: Props) => {
  const [
    createCalendarEvent,
    { isSuccess: createIsSuccess, error: createError },
  ] = useCreateCalendarEventMutation();
  const [
    updateCalendarEvent,
    { isSuccess: updateIsSuccess, error: updateError },
  ] = useUpdateCalendarEventMutation();
  const [
    deleteCalendarEvent,
    { isSuccess: deleteIsSuccess, error: deleteError },
  ] = useDeleteCalendarEventMutation();
  const [kind, setKind] = useState<EventKind>(
    (event as CalendarEvent).kind ?? ''
  );
  const [isEdited, setIsEdited] = useState<boolean>(!isCalendarEvent(event));

  const schema = yup.object().shape({
    kind: yup.string().required("Veuillez renseigner le type d'événement."),
  });

  const { message, validate } = useForm(schema, {
    kind,
  });

  const submit = () => {
    validate(() => {
      if (isCalendarEvent(event)) {
        updateCalendarEvent({
          ...event,
          kind: kind!,
        });
      } else {
        createCalendarEvent({
          ...event,
          kind: kind!,
        });
      }
    });
  };

  useEffect(() => {
    if (createIsSuccess || updateIsSuccess || deleteIsSuccess) {
      onClose();
    }
  }, [createIsSuccess, updateIsSuccess, deleteIsSuccess, onClose]);

  return (
    <Popover
      open={true}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Card>
        <CardHeader
          title={
            <AppWithIcon
              iconPosition="float-right"
              icon={
                <IconButton onClick={onClose}>
                  <Close />
                </IconButton>
              }
            >
              {format(event.start, 'eeee d MMMM yyyy', {
                locale: fr,
              }) +
                ' - ' +
                format(event.end, 'eeee d MMMM yyyy', {
                  locale: fr,
                })}
            </AppWithIcon>
          }
          titleTypographyProps={{
            fontSize: theme.typography.h3.fontSize,
            fontWeight: theme.typography.h3.fontWeight,
            paddingBottom: '0.5rem',
            borderBottom: '1px solid',
          }}
          sx={{ paddingBottom: 0 }}
        />
        <CardContent>
          <AppError
            open={!!updateError || !!createError || !!deleteError}
            message={
              (createError ?? updateError ?? (deleteError as any))?.data.message
            }
          />
          {isEdited ? (
            <FormControl>
              <RadioGroup
                value={kind}
                onChange={(_, value) => setKind(value as EventKind)}
              >
                <FormControlLabel
                  value="Unavailable"
                  control={<Radio />}
                  label={eventKindLabel('Unavailable')}
                />
                <FormControlLabel
                  value="Rental"
                  control={<Radio />}
                  label={eventKindLabel('Rental')}
                />
                <FormControlLabel
                  value="Other"
                  control={<Radio />}
                  label={eventKindLabel('Other')}
                />
              </RadioGroup>
            </FormControl>
          ) : (
            <AppWithIcon
              icon={
                <>
                  <IconButton onClick={() => setIsEdited(true)} color="primary">
                    <Edit />
                  </IconButton>
                  <IconButton
                    onClick={() =>
                      deleteCalendarEvent((event as CalendarEvent).id)
                    }
                    color="error"
                  >
                    <Delete />
                  </IconButton>
                </>
              }
              iconPosition="float-right"
            >
              <Typography>{eventKindLabel(kind)}</Typography>
            </AppWithIcon>
          )}
          <FormHelperText error>{message('kind')}</FormHelperText>
        </CardContent>
        <CardActions>
          {(!isCalendarEvent(event) || isEdited) && (
            <>
              <Button
                variant="outlined"
                onClick={() =>
                  isCalendarEvent(event) ? setIsEdited(false) : onClose()
                }
              >
                Annuler
              </Button>
              <Button variant="contained" onClick={submit}>
                Enregistrer
              </Button>
            </>
          )}
        </CardActions>
      </Card>
    </Popover>
  );
};

export default CalendarEventPopover;
