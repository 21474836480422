import React, { useEffect, useState } from 'react';
import { Alert, Snackbar } from '@mui/material';

interface Props {
  isLoading: boolean;
  isError: boolean;
}

const AppErrorSnackbar = ({ isLoading, isError }: Props) => {
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  useEffect(() => {
    if (!isLoading && isError) {
      setOpenSnackbar(true);
    }
  }, [isError, isLoading]);

  return (
    <Snackbar
      open={openSnackbar}
      autoHideDuration={6000}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={() => setOpenSnackbar(false)}
    >
      <Alert
        severity="error"
        sx={{ width: '100%' }}
        onClose={() => setOpenSnackbar(false)}
      >
        Une erreur s'est produite, veuillez réessayer.
      </Alert>
    </Snackbar>
  );
};

export default AppErrorSnackbar;
