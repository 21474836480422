import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Switch,
  Typography,
} from '@mui/material';
import { durationLabel, Price } from 'src/models/Price';
import { PriceDuration } from 'shared/types/PriceDuration';
import AppCloseButton from 'src/components/AppCloseButton/AppCloseButton';
import { RentalKind } from 'shared/types/RentalKind';

interface PriceListItemProps {
  rentalKind: RentalKind;
  duration: PriceDuration;
  value: number;
  activated: boolean;
  onChange: (activated: boolean, price: Price) => void;
}

const PriceListItem = ({
  rentalKind,
  duration,
  value,
  activated,
  onChange,
}: PriceListItemProps) => {
  return (
    <ListItem
      secondaryAction={
        activated && (
          <Box display="flex" alignItems="center">
            <TextField
              value={value}
              onChange={(event) =>
                onChange(activated, {
                  value: Number(event.target.value),
                  duration,
                })
              }
              autoFocus
              type="number"
              inputProps={{
                style: {
                  textAlign: 'right',
                  maxWidth: '100px',
                  fontWeight: 'bold',
                },
              }}
              sx={{ paddingRight: '1rem' }}
              variant="standard"
            />
            <Typography fontWeight="bold" component="span">
              euros
            </Typography>
          </Box>
        )
      }
    >
      <ListItemButton
        onClick={() => onChange(!activated, { value, duration })}
        sx={{ maxWidth: '66%' }}
      >
        <Switch edge="start" checked={activated} />
        <ListItemText
          primary={`Prix ${durationLabel(rentalKind, duration)}`}
          secondary={activated ? <></> : <>Tarif non proposé</>}
        />
      </ListItemButton>
    </ListItem>
  );
};

interface Props {
  rentalKind: RentalKind;
  prices?: Price[];
  open: boolean;
  onSubmit: (prices: Price[]) => void;
  onClose: () => void;
}

const PriceDialog = ({
  rentalKind,
  prices,
  open,
  onSubmit,
  onClose,
}: Props) => {
  const [activatedPrices, setActivatedPrice] = useState<
    { activated: boolean; price: Price }[]
  >(
    (prices ?? []).map((price) => ({
      activated: true,
      price,
    }))
  );

  const submit = () => {
    onSubmit(
      activatedPrices
        .filter((activatedPrice) => activatedPrice.activated)
        .map((activatedPrice) => activatedPrice.price)
    );
  };

  const getActivatedPrice = (duration: PriceDuration) =>
    activatedPrices.find((_) => _.price.duration === duration);
  const changeActivatedPrice = (activated: boolean, price: Price) =>
    setActivatedPrice([
      ...activatedPrices.filter((_) => _.price.duration !== price.duration),
      { activated, price },
    ]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      disableScrollLock
    >
      <DialogTitle>
        Tarifs
        <AppCloseButton onClose={onClose} />
      </DialogTitle>
      <DialogContent>
        <List>
          {/*<PriceListItem*/}
          {/*  duration="12 hours"*/}
          {/*  value={getActivatedPrice('12 hours')?.price.value ?? 0}*/}
          {/*  activated={!!getActivatedPrice('12 hours')?.activated}*/}
          {/*  onChange={changeActivatedPrice}*/}
          {/*/>*/}
          <PriceListItem
            rentalKind={rentalKind}
            duration="1 day"
            value={getActivatedPrice('1 day')?.price.value ?? 0}
            activated={!!getActivatedPrice('1 day')?.activated}
            onChange={changeActivatedPrice}
          />
          <PriceListItem
            rentalKind={rentalKind}
            duration="2 days"
            value={getActivatedPrice('2 days')?.price.value ?? 0}
            activated={!!getActivatedPrice('2 days')?.activated}
            onChange={changeActivatedPrice}
          />
          <PriceListItem
            rentalKind={rentalKind}
            duration="7 days"
            value={getActivatedPrice('7 days')?.price.value ?? 0}
            activated={!!getActivatedPrice('7 days')?.activated}
            onChange={changeActivatedPrice}
          />
        </List>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Annuler
        </Button>
        <Button variant="contained" onClick={submit}>
          Enregistrer les modifications
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PriceDialog;
