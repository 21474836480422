import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Alert, Box, FormHelperText, Typography } from '@mui/material';
import * as yup from 'yup';
import { emailValidator, passwordValidator, useForm } from 'src/hooks/useForm';
import Divider from '@mui/material/Divider';
import { useSignInMutation } from 'src/services/account.service';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'src/hooks/useStore';
import authSlice from 'src/store/reducers/authSlice';
import AccountForgotPasswordButton from 'src/components/Account/AccountForgotPassword/AccountForgotPasswordButton';

interface Props {
  onNavigate?: () => void;
  onConnect?: () => void;
  showSignup?: boolean;
}

const AccountSigninForm = ({
  onNavigate,
  onConnect,
  showSignup = true,
}: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [signIn, { isError: isSignInError }] = useSignInMutation();

  const schema = yup.object().shape({
    email: emailValidator,
    password: passwordValidator,
  });

  const { message, validate, messageType } = useForm(schema, {
    email,
    password,
  });

  const submit = async () => {
    await validate(() => {
      signIn({
        email,
        password,
      })
        .unwrap()
        .then((authUser) => {
          dispatch(authSlice.actions.signinUser({ authUser }));
          onConnect?.();
        });
    });
  };

  return (
    <Box component="form">
      <TextField
        value={email}
        onChange={(event) => setEmail(event.target.value)}
        autoFocus
        label="Votre email"
        type="email"
        fullWidth
        margin="normal"
        variant="standard"
        error={messageType('email') === 'error'}
        required
      />
      <FormHelperText error>{message('email')}</FormHelperText>
      <TextField
        value={password}
        onChange={(event) => setPassword(event.target.value)}
        autoFocus
        label="Mot de passe"
        type="password"
        fullWidth
        margin="normal"
        variant="standard"
        error={messageType('password') === 'error'}
        required
      />
      <FormHelperText error>{message('password')}</FormHelperText>
      {isSignInError && (
        <Alert severity="error">
          Identifiants non valide, veuillez réessayer.
        </Alert>
      )}
      <Button
        variant="contained"
        onClick={submit}
        sx={{ marginTop: '2rem' }}
        fullWidth
      >
        Me connecter
      </Button>
      <AccountForgotPasswordButton onNavigate={onNavigate} />
      {showSignup && (
        <>
          <Divider sx={{ marginY: '1rem' }}>OU</Divider>
          <Typography variant="h6">
            Vous n'avez pas encore de compte ?
          </Typography>
          <Button
            variant="outlined"
            onClick={() => {
              navigate('/inscription');
              onNavigate?.();
            }}
            fullWidth
            sx={{ marginY: '1rem' }}
          >
            Inscrivez-vous
          </Button>
        </>
      )}
    </Box>
  );
};

export default AccountSigninForm;
