import config from '../utils/config';
import { Booking, DraftBooking } from '../models/Booking';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { withAuthHeader } from 'src/services/auth-headers';

export const bookingsApi = createApi({
  reducerPath: 'bookingsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.apiEndpoint}/api/bookings`,
    prepareHeaders: withAuthHeader,
  }),
  endpoints: (builder) => ({
    checkBooking: builder.mutation<Booking, DraftBooking>({
      query: (draftBooking) => ({
        url: 'check',
        method: 'POST',
        body: draftBooking,
      }),
    }),
  }),
});

export const { useCheckBookingMutation } = bookingsApi;
