import React, { useEffect, useMemo } from 'react';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Container,
  Typography,
} from '@mui/material';
import { H1 } from 'src/components/Styled/Styled';
import { useDocumentTitle } from 'src/hooks/useDocumentTitle';
import {
  useCheckEmailMutation,
  useSignUpMutation,
} from 'src/services/account.service';
import { Brand } from 'shared/constants';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AccountSignupForm from 'src/components/Account/AccountSignupForm/AccountSignupForm';
import AccountSigninForm from 'src/components/Account/AccountSigninForm/AccountSigninForm';

type SignupState =
  | 'Loading'
  | 'Init'
  | 'Submitted'
  | 'Confirmed'
  | 'ConfirmError';

const SignupView = () => {
  useDocumentTitle('Créez votre compte');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const tokenId = searchParams.get('tokenId');

  const [
    signUp,
    { isLoading: isSignUpLoading, isSuccess: isSignUpSuccess, data: draftUser },
  ] = useSignUpMutation({
    fixedCacheKey: 'shared-signup',
  });
  const [
    checkEmail,
    {
      isLoading: isCheckEmailLoading,
      isSuccess: isCheckEmailSuccess,
      isError: isCheckEmailError,
    },
  ] = useCheckEmailMutation();

  useEffect(() => {
    if (tokenId) {
      checkEmail(tokenId);
    }
  }, [tokenId]); // eslint-disable-line react-hooks/exhaustive-deps

  const signupState: SignupState = useMemo(() => {
    if (isSignUpLoading || isCheckEmailLoading) {
      return 'Loading';
    } else if (isCheckEmailSuccess) {
      return 'Confirmed';
    } else if (isCheckEmailError) {
      return 'ConfirmError';
    } else if (isSignUpSuccess) {
      return 'Submitted';
    } else {
      return 'Init';
    }
  }, [
    isSignUpLoading,
    isSignUpSuccess,
    isCheckEmailLoading,
    isCheckEmailError,
    isCheckEmailSuccess,
  ]);

  return (
    <Container maxWidth="sm">
      {signupState === 'Loading' && (
        <Box sx={{ textAlign: 'center', marginY: '10rem' }}>
          <CircularProgress />
        </Box>
      )}
      {signupState === 'Confirmed' && (
        <Box>
          <H1>Félicitations, votre inscription est terminée !</H1>
          <Alert severity="success" sx={{ marginY: '2rem' }}>
            <AlertTitle>Connectez-vous</AlertTitle>
            <Typography sx={{ paddingBottom: '1rem' }}>
              Vous pouvez désormais profiter de {Brand} en vous connectant avec
              votre email et votre mot de passe.
            </Typography>
          </Alert>
          <AccountSigninForm
            onConnect={() => navigate('/')}
            showSignup={false}
          />
        </Box>
      )}
      {signupState === 'ConfirmError' && (
        <Box>
          <H1>Echec de l'activation de votre compte</H1>
          <Alert severity="error" sx={{ marginY: '2rem' }}>
            <AlertTitle>Lien invalide</AlertTitle>
            <Typography>Le lien n'est pas valide ou est expiré....</Typography>
          </Alert>
        </Box>
      )}
      {signupState === 'Submitted' && (
        <Box>
          <H1>
            Merci, finalisez votre inscription en confirmant votre adresse mail
          </H1>
          <Alert severity="success" sx={{ marginY: '2rem' }}>
            <AlertTitle>Un mail de confirmation vous a été envoyé</AlertTitle>
            <Typography sx={{ paddingBottom: '1rem' }}>
              Pour finaliser votre inscription sur {Brand}, vous devez confirmer
              votre adresse mail.
            </Typography>
            <Typography sx={{ paddingBottom: '1rem' }}>
              Pour cela rendez vous sur votre boite mail, vous avez dû recevoir 
              <b>un mail de vérification</b> pour vérifier votre identité.
            </Typography>
            <Typography sx={{ paddingBottom: '1rem' }}>
              <b>Vous ne trouvez pas le mail ?</b> <br />
              Vérifiez qu’il ne s’est pas glissé dans vos spams ou 
              <Button
                variant="text"
                size="small"
                sx={{ padding: 0 }}
                onClick={() => {
                  if (draftUser) {
                    signUp(draftUser);
                  }
                }}
              >
                renvoyer le mail
              </Button>
            </Typography>
          </Alert>
        </Box>
      )}
      {signupState === 'Init' && (
        <>
          <H1>Créez votre compte</H1>
          <AccountSignupForm />
        </>
      )}
    </Container>
  );
};

export default SignupView;
