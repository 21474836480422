import React from 'react';
import { Card, CardMedia, Dialog, Fab } from '@mui/material';
import { SpotFile } from 'src/models/SpotFile';
import { Close } from '@mui/icons-material';

interface Props {
  spotFile: SpotFile;
  open: boolean;
  onClose: () => void;
}

const SpotImageDialog = ({ spotFile, open, onClose }: Props) => {
  return (
    <Dialog onClose={onClose} open={open} maxWidth="xl" disableScrollLock>
      <Card>
        <CardMedia component="img" image={spotFile.path}></CardMedia>
        <Fab
          color="default"
          sx={{
            position: 'absolute !important',
            top: '1rem',
            right: '1rem',
          }}
          size="small"
          onClick={onClose}
        >
          <Close />
        </Fab>
      </Card>
    </Dialog>
  );
};

export default SpotImageDialog;
