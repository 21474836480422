import { useMemo } from 'react';
import { useAppSelector } from 'src/hooks/useStore';
import { useGetUserInfosQuery } from 'src/services/account.service';
import { Spot } from 'src/models/Spot';
import { UserRole } from 'shared/types/UserRole';

export const useAuthentication = () => {
  const { authUser } = useAppSelector((state) => state.auth);

  const { data: userInfos } = useGetUserInfosQuery(authUser?.userId!, {
    skip: !authUser?.userId,
  });

  const isAuthenticated = useMemo(() => !!authUser?.userId, [authUser]);

  const hasRole = useMemo(
    () => (userRole: UserRole) =>
      isAuthenticated && userInfos?.roles.includes(userRole),
    [userInfos, isAuthenticated]
  );

  const hasAnnouncementPermission = useMemo(
    () =>
      isAuthenticated &&
      (hasRole('Admin') || hasRole('Guide') || hasRole('PondOwner')),
    [isAuthenticated, hasRole]
  );

  const isAdmin = useMemo(() => hasRole('Admin'), [hasRole]);

  const isSpotOwner = useMemo(
    () =>
      (spot?: Spot): boolean =>
        isAuthenticated &&
        (userInfos?.roles.includes('Admin') ||
          (spot?.userId !== undefined && spot.userId === authUser?.userId)),
    [authUser, userInfos, isAuthenticated]
  );

  return {
    userId: authUser?.userId,
    userInfos,
    isAuthenticated,
    hasRole,
    hasAnnouncementPermission,
    isAdmin,
    isSpotOwner,
  };
};
