import React from 'react';
import { Container, Typography } from '@mui/material';
import { useDocumentTitle } from 'src/hooks/useDocumentTitle';
import { H1, H2 } from 'src/components/Styled/Styled';
import { Brand, Website } from 'shared/constants';
import { format } from 'date-fns';

const TermsOfUseView = () => {
  useDocumentTitle("Conditions Générales d'Utilisation");

  return (
    <Container maxWidth="lg">
      <H1>Conditions Générales d'Utilisation</H1>
      <Typography>En vigueur au {format(new Date(), 'dd/MM/yyyy')}</Typography>
      <Typography sx={{ marginY: '1rem' }}>
        Les présentes conditions générales d'utilisation (dites « <b>CGU</b> »)
        ont pour objet l'encadrement juridique des modalités de mise à
        disposition du site et des services par {Brand} et de définir les
        conditions d’accès et d’utilisation des services par « l'
        <b>Utilisateur</b> ».
      </Typography>
      <Typography sx={{ marginY: '1rem' }}>
        Les présentes CGU sont accessibles sur le site à la rubrique « 
        <b>Conditions Générales d'Utilisation</b> ».
      </Typography>
      <Typography sx={{ marginY: '1rem' }}>
        Toute inscription ou utilisation du site implique l'acceptation sans
        aucune réserve ni restriction des présentes CGU par l’utilisateur. Lors
        de l'inscription sur le site via le formulaire d’inscription, chaque
        utilisateur accepte expressément les présentes CGU en cochant la case
        précédant le texte suivant : « Je reconnais avoir lu et compris les CGU
        et je les accepte ». En cas de non-acceptation des CGU stipulées dans le
        présent contrat, l'Utilisateur se doit de renoncer à l'accès des
        services proposés par le site.{' '}
      </Typography>
      <Typography sx={{ marginY: '1rem' }}>
        {Brand} se réserve le droit de modifier unilatéralement et à tout moment
        le contenu des présentes CGU.
      </Typography>

      <H2 sx={{ marginTop: '2rem' }}>ARTICLE 1 : Les mentions légales</H2>
      <Typography sx={{ marginY: '1rem' }}>
        L’édition et la direction de la publication du site {Website} est
        assurée par Lucas Colras, domicilié 8 rue delescluze - 87000 Limoges
        <br />
        Téléphone : 0610138663
        <br />
        Email : lucas@fishtonspot.fr
      </Typography>
      <Typography sx={{ marginY: '1rem' }}>
        Le site est hébergé par la société OVH : Raison sociale : OVH SAS. Siège
        : 2 rue Kellerman – 59100 Roubaix.
      </Typography>

      <H2 sx={{ marginTop: '2rem' }}>ARTICLE 2 : Accès au site</H2>
      <Typography sx={{ marginY: '1rem' }}>
        Le site {Website} est une plateforme de mise en relation d'hôte et de
        pêcheurs qui permet à l'Utilisateur un accès gratuit aux services
        suivants :
        <ul>
          <li>Recherche de lieu de pêche</li>
          <li>Recherche de guide de pêche</li>
          <li>Messagerie avec l'hôte </li>
          <li>Calendrier de réservation</li>
        </ul>
      </Typography>
      <Typography sx={{ marginY: '1rem' }}>
        Le site est accessible gratuitement en tout lieu à tout Utilisateur
        ayant un accès à Internet. Tous les frais supportés par l'Utilisateur
        pour accéder au service (matériel informatique, logiciels, connexion
        Internet, etc.) sont à sa charge. L’Utilisateur non membre n'a pas accès
        aux services réservés. Pour cela, il doit s’inscrire en remplissant le
        formulaire. En acceptant de s’inscrire aux services réservés,
        l’Utilisateur membre s’engage à fournir des informations sincères et
        exactes concernant son état civil et ses coordonnées, notamment son
        adresse email.{' '}
      </Typography>
      <Typography sx={{ marginY: '1rem' }}>
        Pour accéder aux services, l’Utilisateur doit ensuite s'identifier à
        l'aide de son email et du mot de passe renseignés lors de son
        inscription.
      </Typography>
      <Typography sx={{ marginY: '1rem' }}>
        Tout Utilisateur membre régulièrement inscrit pourra également
        solliciter sa désinscription en se rendant à la page dédiée sur son
        espace personnel. Celle-ci sera effective dans un délai raisonnable.
      </Typography>
      <Typography sx={{ marginY: '1rem' }}>
        Tout événement dû à un cas de force majeure ayant pour conséquence un
        dysfonctionnement du site ou serveur et sous réserve de toute
        interruption ou modification en cas de maintenance, n'engage pas la
        responsabilité de {Website}. Dans ces cas, l’Utilisateur accepte ainsi
        ne pas tenir rigueur à l’éditeur de toute interruption ou suspension de
        service, même sans préavis.
      </Typography>
      <Typography sx={{ marginY: '1rem' }}>
        L'Utilisateur a la possibilité de contacter le site par messagerie
        électronique à l’adresse email de l’éditeur communiqué à l’ARTICLE 1.
      </Typography>

      <H2 sx={{ marginTop: '2rem' }}>ARTICLE 3 : Collecte des données</H2>
      <Typography sx={{ marginY: '1rem' }}>
        Le site est exempté de déclaration à la Commission Nationale
        Informatique et Libertés (CNIL) dans la mesure où il ne collecte aucune
        donnée concernant les Utilisateurs.
      </Typography>

      <H2 sx={{ marginTop: '2rem' }}>ARTICLE 4 : Propriété intellectuelle</H2>
      <Typography sx={{ marginY: '1rem' }}>
        Les marques, logos, signes ainsi que tous les contenus du site (textes,
        images, son…) font l'objet d'une protection par le Code de la propriété
        intellectuelle et plus particulièrement par le droit d'auteur.
      </Typography>
      <Typography sx={{ marginY: '1rem' }}>
        L'Utilisateur doit solliciter l'autorisation préalable du site pour
        toute reproduction, publication, copie des différents contenus. Il
        s'engage à une utilisation des contenus du site dans un cadre
        strictement privé, toute utilisation à des fins commerciales et
        publicitaires est strictement interdite.
      </Typography>
      <Typography sx={{ marginY: '1rem' }}>
        Toute représentation totale ou partielle de ce site par quelque procédé
        que ce soit, sans l’autorisation expresse de l’exploitant du site
        Internet constituerait une contrefaçon sanctionnée par l’article L 335-2
        et suivants du Code de la propriété intellectuelle.{' '}
      </Typography>
      <Typography sx={{ marginY: '1rem' }}>
        Il est rappelé conformément à l’article L122-5 du Code de propriété
        intellectuelle que l’Utilisateur qui reproduit, copie ou publie le
        contenu protégé doit citer l’auteur et sa source.
      </Typography>

      <H2 sx={{ marginTop: '2rem' }}>ARTICLE 5 : Responsabilité</H2>
      <Typography sx={{ marginY: '1rem' }}>
        Les sources des informations diffusées sur le site {Website} sont
        réputées fiables mais le site ne garantit pas qu’il soit exempt de
        défauts, d’erreurs ou d’omissions.
      </Typography>
      <Typography sx={{ marginY: '1rem' }}>
        Les informations communiquées sont présentées à titre indicatif et
        général sans valeur contractuelle. Malgré des mises à jour régulières,
        le site fishtonspot.fr ne peut être tenu responsable de la modification
        des dispositions administratives et juridiques survenant après la
        publication. De même, le site ne peut être tenu responsable de
        l’utilisation et de l’interprétation de l’information contenue dans ce
        site.
      </Typography>
      <Typography sx={{ marginY: '1rem' }}>
        L'Utilisateur s'assure de garder son mot de passe secret. Toute
        divulgation du mot de passe, quelle que soit sa forme, est interdite. Il
        assume les risques liés à l'utilisation de son identifiant et mot de
        passe. Le site décline toute responsabilité.
      </Typography>
      <Typography sx={{ marginY: '1rem' }}>
        Le site {Website} ne peut être tenu pour responsable d’éventuels virus
        qui pourraient infecter l’ordinateur ou tout matériel informatique de
        l’Internaute, suite à une utilisation, à l’accès, ou au téléchargement
        provenant de ce site.
      </Typography>
      <Typography sx={{ marginY: '1rem' }}>
        La responsabilité du site ne peut être engagée en cas de force majeure
        ou du fait imprévisible et insurmontable d'un tiers.
      </Typography>

      <H2 sx={{ marginTop: '2rem' }}>ARTICLE 6 : Liens hypertextes</H2>
      <Typography sx={{ marginY: '1rem' }}>
        Des liens hypertextes peuvent être présents sur le site. L’Utilisateur
        est informé qu’en cliquant sur ces liens, il sortira du site {Website}.
        Ce dernier n’a pas de contrôle sur les pages web sur lesquelles
        aboutissent ces liens et ne saurait, en aucun cas, être responsable de
        leur contenu.
      </Typography>

      <H2 sx={{ marginTop: '2rem' }}>ARTICLE 7 : Cookies</H2>
      <Typography sx={{ marginY: '1rem' }}>
        L’Utilisateur est informé que lors de ses visites sur le site, des
        cookies peuvent s’installer automatiquement sur son logiciel de
        navigation.
      </Typography>
      <Typography sx={{ marginY: '1rem' }}>
        Les cookies sont de petits fichiers stockés temporairement sur le disque
        dur de l’ordinateur de l’Utilisateur par son navigateur et qui sont
        nécessaires à l’utilisation du site {Website}. Les cookies ne
        contiennent pas d’informations personnelles et ne peuvent pas être
        utilisés pour identifier quelqu’un. Un cookie contient un identifiant
        unique, généré aléatoirement et donc anonyme. Certains cookies expirent
        à la fin de la visite de l’Utilisateur, d’autres restent.{' '}
      </Typography>
      <Typography sx={{ marginY: '1rem' }}>
        L’information contenue dans les cookies est utilisée pour améliorer le
        site {Website}. En naviguant sur le site, L’Utilisateur les accepte.
        L’Utilisateur doit toutefois donner son consentement quant à
        l’utilisation de certains cookies. A défaut d’acceptation, l’Utilisateur
        est informé que certaines fonctionnalités ou pages risquent de lui être
        refusées.
      </Typography>
      <Typography sx={{ marginY: '1rem' }}>
        L’Utilisateur pourra désactiver ces cookies par l’intermédiaire des
        paramètres figurant au sein de son logiciel de navigation.
      </Typography>

      <H2 sx={{ marginTop: '2rem' }}>
        ARTICLE 8 : Publication par l’Utilisateur
      </H2>
      <Typography sx={{ marginY: '1rem' }}>
        Le site permet à l'Utilisateur de publier les contenus suivants :
        Annonces (Localisation / Titre / Photos / Équipements / Description /
        Prix /Disponibilités / Règlement).
      </Typography>
      <Typography sx={{ marginY: '1rem' }}>
        Dans ses publications, l'Utilisateur s’engage à respecter les règles de
        la Netiquette (règles de bonne conduite de l’internet) et les règles de
        droit en vigueur.
      </Typography>
      <Typography sx={{ marginY: '1rem' }}>
        Le site peut exercer une modération sur les publications et se réserve
        le droit de refuser leur mise en ligne, sans avoir à s’en justifier
        auprès de l'Utilisateur.
      </Typography>
      <Typography sx={{ marginY: '1rem' }}>
        L'Utilisateur reste titulaire de l’intégralité de ses droits de
        propriété intellectuelle. Mais en publiant une annonce sur le site, il
        cède à la société éditrice le droit non exclusif et gratuit de
        représenter, reproduire, adapter, modifier, diffuser et distribuer sa
        publication, directement ou par un tiers autorisé, dans le monde entier,
        sur tout support (numérique ou physique), pour la durée de la propriété
        intellectuelle. L'Utilisateur cède notamment le droit d'utiliser sa
        publication sur internet et sur les réseaux de téléphonie mobile.
      </Typography>
      <Typography sx={{ marginY: '1rem' }}>
        Tout contenu mis en ligne par l'Utilisateur est de sa seule
        responsabilité. L'Utilisateur s'engage à ne pas mettre en ligne de
        contenus pouvant porter atteinte aux intérêts de tierces personnes. Tout
        recours en justice engagé par un tiers lésé contre le site sera pris en
        charge par l'Utilisateur. Le contenu de l'Utilisateur peut être à tout
        moment et pour n'importe quelle raison supprimé ou modifié par le site,
        sans préavis.
      </Typography>

      <H2 sx={{ marginTop: '2rem' }}>
        ARTICLE 9 : Droit applicable et juridiction compétente
      </H2>
      <Typography sx={{ marginY: '1rem' }}>
        La législation française s'applique au présent contrat. En cas d'absence
        de résolution amiable d'un litige né entre les parties, les tribunaux
        français seront seuls compétents pour en connaître. Pour toute question
        relative à l’application des présentes CGU, vous pouvez joindre
        l’éditeur aux coordonnées inscrites à l’ARTICLE 1.
      </Typography>
    </Container>
  );
};

export default TermsOfUseView;
