export type FishKind =
  | 'Bar'
  | 'Barbue'
  | 'BlackBass'
  | 'Brochet'
  | 'Carpe'
  | 'Cephalopode'
  | 'Commune'
  | 'Congre'
  | 'Dorade'
  | 'Espadon'
  | 'Esturgeon'
  | 'Gardon'
  | 'Koï'
  | 'Lieu'
  | 'Maquereau'
  | 'Mirroir'
  | 'Pagre'
  | 'Perche'
  | 'Raie'
  | 'Rouget'
  | 'Sandre'
  | 'Silure'
  | 'Thon blanc'
  | 'Thon jaune'
  | 'Thon rouge'
  | 'Truite';

export const FishKinds: FishKind[] = [
  'Bar',
  'Barbue',
  'BlackBass',
  'Brochet',
  'Carpe',
  'Cephalopode',
  'Commune',
  'Congre',
  'Dorade',
  'Espadon',
  'Esturgeon',
  'Gardon',
  'Koï',
  'Lieu',
  'Maquereau',
  'Mirroir',
  'Pagre',
  'Perche',
  'Raie',
  'Rouget',
  'Sandre',
  'Silure',
  'Thon blanc',
  'Thon jaune',
  'Thon rouge',
  'Truite',
];
