import React from 'react';
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Skeleton,
  Tooltip,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { SpotAnnouncement } from 'src/models/SpotAnnouncement';
import { findDepartment } from 'shared/models/Department';
import { durationLabel } from 'src/models/Price';
import { Cancel, CheckCircle } from '@mui/icons-material';

export type SpotAnnouncementExtraInfos = 'IsPublished';

interface Props {
  spotAnnouncement: SpotAnnouncement;
  extraInfos?: SpotAnnouncementExtraInfos[];
}

const SpotAnnouncementCard = ({ spotAnnouncement, extraInfos }: Props) => {
  return (
    <>
      <Card
        sx={{
          boxShadow: 'none',
          transition: '0.2s',
          '&:hover': {
            transform: 'scale(1.02)',
          },
        }}
      >
        <Tooltip
          title={spotAnnouncement.location}
          placement="top"
          followCursor={true}
        >
          <CardActionArea>
            <Link
              to={`/annonces?id=${spotAnnouncement.id}`}
              style={{ color: 'initial', textDecoration: 'initial' }}
            >
              {spotAnnouncement.imagePath ? (
                <CardMedia
                  component="img"
                  src={spotAnnouncement.imagePath}
                  srcSet={spotAnnouncement.imagePath}
                  alt=""
                  sx={{
                    aspectRatio: '1/1',
                    borderRadius: '8px',
                    filter:
                      'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
                  }}
                />
              ) : (
                <Skeleton
                  variant="rounded"
                  sx={{ aspectRatio: '1 / 1', height: '100%' }}
                  animation={false}
                />
              )}
              <CardContent sx={{ paddingLeft: 0, paddingRight: 0 }}>
                <Typography
                  variant="subtitle2"
                  component="div"
                  fontWeight="bold"
                  sx={{
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                >
                  {spotAnnouncement.location}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  fontWeight="500"
                >
                  <i>
                    {findDepartment(spotAnnouncement.department)?.name} (
                    {findDepartment(spotAnnouncement.department)?.code})
                  </i>
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  fontWeight="500"
                  sx={{
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                >
                  {spotAnnouncement.fishes.join(', ')}
                </Typography>
                {spotAnnouncement.price && (
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    fontWeight="600"
                  >
                    <span style={{ color: 'black' }}>
                      À partir de 
                      <b>{spotAnnouncement.price} € </b>
                    </span>
                    {spotAnnouncement.priceDuration &&
                      `${durationLabel(
                        spotAnnouncement.rentalKind,
                        spotAnnouncement.priceDuration
                      )}`}
                  </Typography>
                )}
              </CardContent>
            </Link>
            {extraInfos?.includes('IsPublished') && (
              <>
                {spotAnnouncement.published ? (
                  <CheckCircle
                    sx={{
                      position: 'absolute',
                      right: '0.25rem',
                      top: '0.25rem',
                      color: 'secondary.main',
                      backgroundColor: 'success.main',
                      borderRadius: '50%',
                    }}
                  />
                ) : (
                  <Cancel
                    sx={{
                      position: 'absolute',
                      right: '0.25rem',
                      top: '0.25rem',
                      color: 'secondary.main',
                      backgroundColor: 'error.main',
                      borderRadius: '50%',
                    }}
                  />
                )}
              </>
            )}
          </CardActionArea>
        </Tooltip>
      </Card>
    </>
  );
};

export default SpotAnnouncementCard;
