import config from '../utils/config';
import { Prospect } from '../models/Prospect';

const registerProspect = async (prospect: Prospect): Promise<void> => {
  return await fetch(`${config.apiEndpoint}/api/prospects`, {
    method: 'POST',
    body: JSON.stringify(prospect),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((_) => _.json());
};

const prospectService = {
  registerProspect,
};

export default prospectService;
