import * as React from 'react';
import { ChangeEvent, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Typography,
} from '@mui/material';
import * as yup from 'yup';
import {
  emailValidator,
  passwordConfirmationValidator,
  passwordValidator,
  useForm,
} from 'src/hooks/useForm';
import Divider from '@mui/material/Divider';
import { UserRole } from 'shared/types/UserRole';
import { useSignUpMutation } from 'src/services/account.service';
import { Brand } from 'shared/constants';
import { useNavigate } from 'react-router-dom';
import AccountRoleCheckbox from 'src/components/Account/AccountRoleCheckbox/AccountRoleCheckbox';
import AppError from 'src/components/AppError/AppError';

interface Props {
  onNavigate?: () => void;
}

const AccountSignupForm = ({ onNavigate }: Props) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [userName, setUserName] = useState('');
  const [roles, setRoles] = useState<UserRole[]>([]);
  const [agreement, setAgreemnet] = useState<boolean>(false);

  const [signUp, { isError: isSignUpError }] = useSignUpMutation({
    fixedCacheKey: 'shared-signup',
  });

  const schema = yup.object().shape({
    email: emailValidator,
    password: passwordValidator,
    passwordConfirm: passwordConfirmationValidator('password'),
    userName: yup
      .string()
      .min(3, "Veuillez renseigner votre nom d'utilisateur."),
    roles: yup
      .array()
      .of(yup.string())
      .ensure()
      .min(1, 'Veuillez sélectionner au moins un profil'),
    agreement: yup
      .boolean()
      .oneOf(
        [true],
        "Veuillez accepter les conditions générales d'utilisation"
      ),
  });

  const { message, validate, messageType } = useForm(schema, {
    email,
    password,
    passwordConfirm,
    userName,
    roles,
    agreement,
  });

  const submit = async () => {
    await validate(() => {
      signUp({
        email,
        password,
        roles,
        userName,
      })
        .unwrap()
        .then(() => {
          navigate('/inscription');
          onNavigate?.();
        });
    });
  };

  const handleRoleCheckboxChange = (
    e: ChangeEvent<HTMLInputElement>,
    role: UserRole
  ) => {
    setRoles([
      ...roles.filter((_) => _ !== role),
      ...(e.target.checked ? [role] : []),
    ]);
  };

  return (
    <Box component="form">
      <TextField
        value={email}
        onChange={(event) => setEmail(event.target.value)}
        autoFocus
        label="Votre email"
        type="email"
        fullWidth
        margin="normal"
        variant="standard"
        error={messageType('email') === 'error'}
        required
      />
      <FormHelperText error>{message('email')}</FormHelperText>
      <TextField
        value={userName}
        onChange={(event) => setUserName(event.target.value)}
        autoFocus
        label={`Votre nom d'utilisateur sur ${Brand}`}
        type="text"
        fullWidth
        margin="normal"
        variant="standard"
        error={messageType('userName') === 'error'}
        required
      />
      <FormHelperText error>{message('userName')}</FormHelperText>
      <FormControl
        fullWidth
        variant="standard"
        margin="normal"
        error={messageType('roles') === 'error'}
      >
        <FormLabel>
          Votre profil (vous pouvez sélectionner plusieurs réponses)
        </FormLabel>
        <FormGroup>
          <AccountRoleCheckbox
            checkboxRole={'PondOwner'}
            userRoles={roles}
            onChange={handleRoleCheckboxChange}
          />
          <AccountRoleCheckbox
            checkboxRole={'Guide'}
            userRoles={roles}
            onChange={handleRoleCheckboxChange}
          />
          {/*<AccountRoleCheckbox*/}
          {/*  checkboxRole={'Customer'}*/}
          {/*  userRoles={roles}*/}
          {/*  onChange={handleRoleCheckboxChange}*/}
          {/*/>*/}
        </FormGroup>
      </FormControl>
      <FormHelperText error>{message('roles')}</FormHelperText>
      <TextField
        value={password}
        onChange={(event) => setPassword(event.target.value)}
        autoFocus
        label="Mot de passe"
        type="password"
        fullWidth
        margin="normal"
        variant="standard"
        error={messageType('password') === 'error'}
        required
      />
      <FormHelperText error>{message('password')}</FormHelperText>
      <TextField
        value={passwordConfirm}
        onChange={(event) => setPasswordConfirm(event.target.value)}
        autoFocus
        label="Confirmation du mot de passe"
        type="password"
        fullWidth
        margin="normal"
        variant="standard"
        error={messageType('passwordConfirm') === 'error'}
        required
      />
      <FormHelperText error>{message('passwordConfirm')}</FormHelperText>
      <FormControlLabel
        control={
          <Checkbox
            checked={agreement}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setAgreemnet(e.target.checked)
            }
          />
        }
        label="J'ai lu et j'accepte les CGU"
      />
      <FormHelperText error>{message('agreement')}</FormHelperText>
      <AppError open={isSignUpError} />
      <Button
        variant="contained"
        onClick={submit}
        sx={{ marginY: '2rem' }}
        fullWidth
      >
        Créer mon compte
      </Button>
      <Divider sx={{ marginY: '1rem' }}>OU</Divider>
      <Typography variant="h6">Vous avez déjà un compte ?</Typography>
      <Button
        variant="outlined"
        onClick={() => {
          navigate('/connexion');
          onNavigate?.();
        }}
        fullWidth
        sx={{ marginY: '1rem' }}
      >
        Connectez-vous
      </Button>
    </Box>
  );
};

export default AccountSignupForm;
