import React from 'react';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import HeaderLink from 'src/components/Header/HeaderLink';

function HeaderMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleClick}
        color="inherit"
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          display: { xs: 'block', md: 'none' },
        }}
      >
        <MenuItem onClick={handleClose}>
          <HeaderLink to="/#lieux-de-peche" value="Lieux de pêche" />
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <HeaderLink to="guides" value="Guides" />
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <HeaderLink
            to="objectifs"
            value="Pourquoi faire ?"
            scrollOptions={{ offset: -40 }}
          />
        </MenuItem>
      </Menu>
    </Box>
  );
}

export default HeaderMenu;
