import React from 'react';
import { Equipment } from '../../../../shared/types/Equipment';
import {
  AcUnit,
  Bungalow,
  Check,
  Coffee,
  DirectionsBike,
  FourGMobiledata,
  Kitchen,
  LocalParking,
  OutdoorGrill,
  Restaurant,
  Rowing,
  Shower,
  Soap,
  Wash,
  Wc,
  Wifi,
} from '@mui/icons-material';

interface Props {
  equipment: Equipment | string;
  color?: any;
}

const EquipmentIcon = ({ equipment, color }: Props) => {
  switch (equipment) {
    case 'Produit à main':
      return <Soap color={color} />;
    case 'WC':
      return <Wc color={color} />;
    case 'Douche':
      return <Shower color={color} />;
    case 'Lavabo':
      return <Wash color={color} />;
    case 'Barbecue':
      return <OutdoorGrill color={color} />;
    case 'Frigo':
      return <Kitchen color={color} />;
    case 'Congélateur':
      return <AcUnit color={color} />;
    case 'Cottage':
      return <Bungalow color={color} />;
    case 'Réseaux 3G/4G':
      return <FourGMobiledata color={color} />;
    case 'Wifi':
      return <Wifi color={color} />;
    case 'Location de barque':
      return <Rowing color={color} />;
    case 'Location de vélos':
      return <DirectionsBike color={color} />;
    case "Parking à l'entrée":
      return <LocalParking color={color} />;
    case 'Parking au poste':
      return <LocalParking color={color} />;
    case 'Restauration':
      return <Restaurant color={color} />;
    case 'Boissons chaudes':
      return <Coffee color={color} />;
    default:
      return <Check color={color} />;
  }
};

export default EquipmentIcon;
