import React from 'react';
import { Grid, useMediaQuery } from '@mui/material';
import { Spot } from 'src/models/Spot';
import { useFindSpotFilesQuery } from 'src/services/spotFile.service';
import { theme } from 'src/theme';
import SpotImage from './SpotImage';

interface Props {
  spot: Spot;
}

const SpotFiles = ({ spot }: Props) => {
  const { data: spotFiles, isFetching } = useFindSpotFilesQuery(spot.id);
  const mediaQueryUpLg = useMediaQuery(theme.breakpoints.up('lg'));
  const mediaQueryUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const mediaQueryUpSm = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Grid container spacing={1} sx={{ paddingBottom: '3rem' }}>
      <Grid item xs={12} md={8} sx={{ position: 'relative' }}>
        <SpotImage
          spotFile={spotFiles?.find((_) => _.kind === 'Image1')}
          spot={spot}
          fileKind={'Image1'}
          height={
            mediaQueryUpLg
              ? 608
              : mediaQueryUpMd
              ? 448
              : mediaQueryUpSm
              ? 600
              : 300
          }
          isFetching={isFetching}
        />
      </Grid>
      <Grid
        item
        container
        xs={12}
        md={4}
        direction={{ xs: 'column', sm: 'row', md: 'column' }}
        spacing={1}
      >
        <Grid
          item
          xs={12}
          sm={6}
          sx={{ position: 'relative', flex: '1 !important' }}
        >
          <SpotImage
            spotFile={spotFiles?.find((_) => _.kind === 'Image2')}
            spot={spot}
            fileKind={'Image2'}
            height={
              mediaQueryUpLg
                ? 300
                : mediaQueryUpMd
                ? 220
                : mediaQueryUpSm
                ? 300
                : 260
            }
            isFetching={isFetching}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{ position: 'relative', flex: '1 !important' }}
        >
          <SpotImage
            spotFile={spotFiles?.find((_) => _.kind === 'Image3')}
            spot={spot}
            fileKind={'Image3'}
            height={
              mediaQueryUpLg
                ? 300
                : mediaQueryUpMd
                ? 220
                : mediaQueryUpSm
                ? 300
                : 260
            }
            isFetching={isFetching}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SpotFiles;
