import config from '../utils/config';
import { DraftSpot, Spot } from '../models/Spot';
import { Equipment } from 'shared/types/Equipment';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { FishKind } from 'shared/types/FishKind';
import { SpotAnnouncement } from 'src/models/SpotAnnouncement';
import { withAuthHeader } from 'src/services/auth-headers';
import { getURLSearchParams } from 'src/utils/fetchUtils';
import { SpotFilters } from 'shared/models/SpotFilters';
import { Requirement } from 'shared/types/Requirement';
import { Prohibition } from 'shared/types/Prohibition';
import { Permission } from 'shared/types/Permission';

export const spotApi = createApi({
  reducerPath: 'spotApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.apiEndpoint}/api/spots`,
    prepareHeaders: withAuthHeader,
  }),
  tagTypes: ['Spot', 'SpotAnnouncement'],
  endpoints: (builder) => ({
    getSpot: builder.query<Spot, string>({
      query: (spotId) => spotId,
      providesTags: () => ['Spot'],
    }),
    findSpotAnnouncements: builder.query<SpotAnnouncement[], SpotFilters>({
      query: (spotFilters) =>
        `/announcements?${getURLSearchParams(spotFilters)}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'SpotAnnouncement' as const,
                id,
              })),
              'SpotAnnouncement',
            ]
          : ['SpotAnnouncement'],
    }),
    createSpot: builder.mutation<Spot, DraftSpot>({
      query: (draftSpot) => ({
        url: '',
        method: 'POST',
        body: draftSpot,
      }),
      invalidatesTags: ['Spot', 'SpotAnnouncement'],
    }),
    updateSpotLocation: builder.mutation<
      void,
      { spotId: string; name: string; location: string; department: string }
    >({
      query: ({ spotId, name, location, department }) => ({
        url: `${spotId}/location`,
        method: 'PUT',
        body: { name, location, department },
      }),
      invalidatesTags: (result, error, arg) => [
        'Spot',
        { type: 'SpotAnnouncement', id: arg.spotId },
      ],
    }),
    updateSpotPublication: builder.mutation<
      void,
      { spotId: string; published: boolean }
    >({
      query: ({ spotId, published }) => ({
        url: `${spotId}/publication`,
        method: 'PUT',
        body: { published },
      }),
      invalidatesTags: (result, error, arg) => [
        'Spot',
        { type: 'SpotAnnouncement', id: arg.spotId },
      ],
    }),
    updateSpotDescription: builder.mutation<
      void,
      { spotId: string; description: string }
    >({
      query: ({ spotId, description }) => ({
        url: `${spotId}/description`,
        method: 'PUT',
        body: { description },
      }),
      invalidatesTags: (result, error, arg) => [
        'Spot',
        { type: 'SpotAnnouncement', id: arg.spotId },
      ],
    }),
    updateSpotEquipments: builder.mutation<
      void,
      { spotId: string; equipments: Equipment[] }
    >({
      query: ({ spotId, equipments }) => ({
        url: `${spotId}/equipments`,
        method: 'PUT',
        body: { equipments },
      }),
      invalidatesTags: (result, error, arg) => [
        'Spot',
        { type: 'SpotAnnouncement', id: arg.spotId },
      ],
    }),
    updateSpotRules: builder.mutation<
      void,
      {
        spotId: string;
        requirements: Requirement[];
        prohibitions: Prohibition[];
        permissions: Permission[];
      }
    >({
      query: ({ spotId, requirements, prohibitions, permissions }) => ({
        url: `${spotId}/rules`,
        method: 'PUT',
        body: { requirements, prohibitions, permissions },
      }),
      invalidatesTags: (result, error, arg) => [
        'Spot',
        { type: 'SpotAnnouncement', id: arg.spotId },
      ],
    }),
    updateSpotFishes: builder.mutation<
      void,
      { spotId: string; fishes: FishKind[] }
    >({
      query: ({ spotId, fishes }) => ({
        url: `${spotId}/fishes`,
        method: 'PUT',
        body: { fishes },
      }),
      invalidatesTags: (result, error, arg) => [
        'Spot',
        { type: 'SpotAnnouncement', id: arg.spotId },
      ],
    }),
    deleteSpot: builder.mutation<void, string>({
      query: (spotId) => ({
        url: spotId,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, spotId) => [
        { type: 'SpotAnnouncement', id: spotId },
      ],
    }),
  }),
});

export const {
  useGetSpotQuery,
  useFindSpotAnnouncementsQuery,
  useUpdateSpotLocationMutation,
  useUpdateSpotDescriptionMutation,
  useUpdateSpotEquipmentsMutation,
  useUpdateSpotRulesMutation,
  useUpdateSpotFishesMutation,
  useUpdateSpotPublicationMutation,
  useCreateSpotMutation,
  useDeleteSpotMutation,
} = spotApi;
