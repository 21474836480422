import React, { ChangeEvent, useState } from 'react';
import { Spot } from 'src/models/Spot';
import { Edit } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  TextareaAutosize,
  Typography,
} from '@mui/material';
import { theme } from 'src/theme';
import DOMPurify from 'dompurify';
import { useAuthentication } from 'src/hooks/useAuthentication';
import { useUpdateSpotDescriptionMutation } from 'src/services/spot.service';
import { H2 } from '../Styled/Styled';

interface Props {
  spot: Spot;
}

const SpotDescription = ({ spot }: Props) => {
  const { isSpotOwner } = useAuthentication();
  const [isEdited, setIsEdited] = useState(false);
  const [description, setDescription] = useState('');

  const [updateDescription] = useUpdateSpotDescriptionMutation();
  const submitDescription = async () => {
    await updateDescription({ spotId: spot.id, description });
    setIsEdited(false);
  };

  return (
    <Card sx={{ marginBottom: '2rem' }}>
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          <H2>Description du lieu</H2>
          {isSpotOwner(spot) && !isEdited && (
            <Button
              variant="contained"
              startIcon={<Edit />}
              onClick={() => {
                setDescription(spot.description ?? '');
                setIsEdited(true);
              }}
            >
              Modifier
            </Button>
          )}
        </Box>

        <Divider sx={{ marginY: '1rem' }} />
        {!isEdited ? (
          <Typography sx={{ whiteSpace: 'pre-line' }}>
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(spot.description ?? '', {
                  ADD_ATTR: ['target'],
                }),
              }}
            ></span>
          </Typography>
        ) : (
          <TextareaAutosize
            defaultValue={description}
            style={{
              width: '96%',
              fontSize: theme.typography.fontSize,
              fontFamily: theme.typography.fontFamily,
              padding: '2%',
            }}
            autoFocus
            minRows="15"
            onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
              setDescription(event.target.value)
            }
          ></TextareaAutosize>
        )}
      </CardContent>
      {isEdited && (
        <CardActions>
          <Button variant="outlined" onClick={() => setIsEdited(false)}>
            Annuler
          </Button>
          <Button variant="contained" onClick={() => submitDescription()}>
            Enregistrer les modifications
          </Button>
        </CardActions>
      )}
    </Card>
  );
};

export default SpotDescription;
