export type Permission =
  | 'Bateau pneumatique'
  | 'Moteur électrique'
  | 'Float tube'
  | 'Bateau'
  | 'Bateau amorçeur'
  | 'Bas de ligne acier'
  | 'Esches animal'
  | 'Appareils musicaux'
  | 'Accompagnants'
  | 'Baignade'
  | 'Barbecue'
  | 'Produit désinfectant pour poisson'
  | 'Biwy'
  | 'Camping car'
  | 'Pêche au leurre'
  | 'Pêche à la carpe'
  | 'Pêche au carnassier'
  | 'Pêche au mort manié'
  | string;

export const PermissionTypes: Permission[] = [
  'Bateau pneumatique',
  'Moteur électrique',
  'Float tube',
  'Bateau',
  'Bateau amorçeur',
  'Bas de ligne acier',
  'Esches animal',
  'Appareils musicaux',
  'Accompagnants',
  'Baignade',
  'Barbecue',
  'Produit désinfectant pour poisson',
  'Biwy',
  'Camping car',
  'Pêche au leurre',
  'Pêche à la carpe',
  'Pêche au carnassier',
  'Pêche au mort manié',
];
