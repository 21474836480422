import config from '../utils/config';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { Price } from 'src/models/Price';
import { withAuthHeader } from 'src/services/auth-headers';

export const pricesApi = createApi({
  reducerPath: 'pricesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.apiEndpoint}/api/prices`,
    prepareHeaders: withAuthHeader,
  }),
  tagTypes: ['Price'],
  endpoints: (builder) => ({
    findSpotPrices: builder.query<Price[], string>({
      query: (spotId) => `/spots/${spotId}`,
      providesTags: ['Price'],
    }),
    updateSpotPrices: builder.mutation<
      void,
      { spotId: string; prices: Price[] }
    >({
      query: ({ spotId, prices }) => ({
        url: `/spots/${spotId}`,
        method: 'PUT',
        body: { prices },
      }),
      invalidatesTags: ['Price'],
    }),
  }),
});

export const { useFindSpotPricesQuery, useUpdateSpotPricesMutation } =
  pricesApi;
