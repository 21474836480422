import { createTheme } from '@mui/material';

export const theme = createTheme({
  typography: {
    fontFamily: [
      '"Montserrat"',
      '"Roboto"',
      '"Helvetica"',
      '"Arial"',
      'sans-serif',
    ].join(','),
    h2: {
      fontWeight: 600,
      fontSize: '25px',
    },
    h3: {
      fontWeight: 500,
      fontSize: '16px',
    },
  },
  palette: {
    primary: {
      main: '#144474',
    },
    secondary: {
      main: '#ffffff',
    },
    text: {
      secondary: '#949494',
    },
  },
  components: {
    MuiCardActionArea: {
      styleOverrides: {
        focusHighlight: {
          background: 'none',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          paddingLeft: 0,
          alignItems: 'start',
        },
      },
    },
  },
});
