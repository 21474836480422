import { UserRole } from 'shared/types/UserRole';

export interface UserInfos {
  email: string;
  userName: string;
  roles: UserRole[];
}

export type DraftUser = UserInfos & {
  password: string;
};

export interface AuthUser {
  userId: string;
  accessToken: string;
}

export const userRoleLabel = (userRole: UserRole) => {
  switch (userRole) {
    case 'Admin':
      return 'Administrateur';
    case 'PondOwner':
      return "Propriétaire d'un lieu de pêche (étang, domaine...)";
    case 'Guide':
      return 'Guide de pêche';
    case 'Customer':
      return "A la recherche d'un spot de pêche ou d'un guide";
  }
};
