import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Container,
  Fab,
  Grid,
  Typography,
} from '@mui/material';
import home from 'src/assets/home.jpg';
import homePurpose from 'src/assets/home-purpose.jpg';
import { styled } from '@mui/system';
import SpotAnnouncementList from 'src/components/SpotAnnouncementList/SpotAnnouncementList';
import { theme } from 'src/theme';
import SpotMap from 'src/components/SpotMap/SpotMap';
import { List, Map } from '@mui/icons-material';

const H1 = styled('h1')(({ theme }) =>
  theme.unstable_sx({
    color: 'white',
    fontWeight: 700,
    fontSize: { xs: '7vw', sm: '3.5vw' },
    paddingTop: { xs: '2%', sm: 0 },
    marginBottom: { xs: '0.5rem', sm: 'revert' },
    marginTop: { xs: '0.5rem', sm: 'revert' },
    textAlign: { xs: 'center', sm: 'left' },
    textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  })
);
const H2 = styled('h2')(({ theme }) =>
  theme.unstable_sx({
    color: theme.palette.primary.light,
    textTransform: 'uppercase',
    fontWeight: 400,
    fontSize: { xs: '5vw', sm: '1.75vw' },
    marginBottom: { xs: '0.5rem', sm: 'revert' },
    marginTop: 0,
    textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  })
);
const H3 = styled('h3')(({ theme }) =>
  theme.unstable_sx({
    fontWeight: 600,
    fontSize: { xs: '4vw', sm: '1.25vw' },
    paddingTop: { xs: '2%', sm: 0 },
    marginBottom: { xs: '0.5rem', sm: 'revert' },
    marginTop: { xs: '0.5rem', sm: 'revert' },
  })
);
const BackgroundImageSection = styled('section')(({ theme }) =>
  theme.unstable_sx({
    width: '100%',
    minHeight: {
      xs: '200px',
      sm: '282px',
      md: '375px',
      lg: '480px',
      xl: '600px',
    },
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'center',
    backgroundPositionY: 'center',
    display: 'flex',
  })
);

const HomeView = () => {
  const [showMap, setShowMap] = React.useState(false);

  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          bottom: {
            xs: '7rem',
            sm: '4rem',
          },
          width: '100%',
          textAlign: { xs: 'center', sm: 'center' },
          zIndex: 1000,
        }}
      >
        <Fab
          variant="extended"
          color="primary"
          style={{ marginRight: '1rem' }}
          onClick={() => setShowMap(!showMap)}
        >
          {showMap ? (
            <>
              <List style={{ marginRight: '1rem' }} />
              Liste des spots
            </>
          ) : (
            <>
              <Map style={{ marginRight: '1rem' }} />
              Carte des spots
            </>
          )}
        </Fab>
      </Box>
      {showMap ? (
        <SpotMap />
      ) : (
        <>
          <BackgroundImageSection sx={{ backgroundImage: `url(${home})` }}>
            <Container
              maxWidth="xl"
              sx={{
                flexDirection: 'row',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <H1 sx={{ flex: 1 }}>
                Pêchez, partout, quand
                <br />
                vous voulez
              </H1>
            </Container>
          </BackgroundImageSection>
          <Container maxWidth="xl">
            <section id="lieux-de-peche">
              <SpotAnnouncementList
                title="Lieux de pêche"
                spotKind="Pond"
                published
              />
            </section>
            <section id="guides">
              <SpotAnnouncementList
                title="Guides de pêche"
                spotKind="Guide"
                published
              />
            </section>
          </Container>
          <BackgroundImageSection
            sx={{ backgroundImage: `url(${homePurpose})` }}
          >
            <Container maxWidth="xl" component="section" id="objectifs">
              <Card sx={{ marginY: '3rem', padding: '1rem' }} elevation={15}>
                <CardContent>
                  <Grid container spacing={{ xs: 3, md: 4, xl: 30 }}>
                    <Grid item xs={12} sm={6}>
                      <H2>Pour les hôtes et les guides</H2>
                      <H3>Touchez un public plus large</H3>
                      <Typography color={theme.palette.grey['700']}>
                        En mettant en ligne vos offres sur Fish Ton Spot, vous
                        pouvez toucher un public plus large de pêcheurs en
                        France et à l'étranger. Notre plateforme de réservation
                        d'activités de pêche vous permet de promouvoir vos
                        offres auprès d'une audience diversifiée.
                      </Typography>
                      <H3>Centralisez toutes vos offres</H3>
                      <Typography color={theme.palette.grey['700']}>
                        Avec Fish Ton Spot, vous pouvez centraliser toutes vos
                        offres de pêche en un seul endroit. Que vous proposiez
                        des activités de pêche en mer, en rivière ou en étang,
                        notre site vous permet de présenter vos offres de
                        manière claire et organisée. Cela facilitera la
                        recherche et la réservation de vos offres par les
                        pêcheurs intéressés.
                      </Typography>
                      <H3>Propulsez votre activité de pêche !</H3>
                      <Typography color={theme.palette.grey['700']}>
                        En développant votre présence sur notre plateforme de
                        réservation, vous bénéficiez d'une visibilité accrue en
                        ligne. Boostez votre référencement et permettez aux
                        amateurs de pêche, qu'ils soient locaux ou
                        internationaux, de découvrir et de réserver vos offres
                        simplement.
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <H2>Pour les pêcheurs</H2>
                      <H3>Recherchez votre prochaine sortie</H3>
                      <Typography color={theme.palette.grey['700']}>
                        Trouvez les meilleurs spots de pêche en France en
                        utilisant notre plateforme de réservation d'activités de
                        pêche. Que vous soyez un pêcheur débutant ou
                        expérimenté, notre site vous permet de trouver des lieux
                        de pêche adaptés à vos besoins et à vos préférences
                      </Typography>
                      <H3>Explorez l'univers captivant de la pêche</H3>
                      <Typography color={theme.palette.grey['700']}>
                        Plongez dans la diversité de nos offres, découvrez des
                        lieux exceptionnels et choisissez l'activité qui fait
                        battre votre cœur. De la pêche en rivière à celle en
                        mer, notre plateforme vous offre un éventail d'options.
                      </Typography>
                      <H3>Pêchez, partout, quand vous voulez</H3>
                      <Typography color={theme.palette.grey['700']}>
                        Partez à l'aventure en explorant les meilleurs spots de
                        pêche en France. Que vous soyez en famille, entre amis
                        ou en solo, pêcher avec Fish Ton Spot est une expérience
                        immersive qui vous connecte à la nature et à la passion
                        de la pêche.
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Container>
          </BackgroundImageSection>
        </>
      )}
    </>
  );
};

export default HomeView;
