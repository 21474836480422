import React from 'react';
import {
  Alert,
  Box,
  Card,
  CardContent,
  Fab,
  IconButton,
  Menu,
  MenuItem,
  Stack,
} from '@mui/material';
import bar from 'src/assets/fishes/bar.png';
import barbue from 'src/assets/fishes/barbue.png';
import blackbass from 'src/assets/fishes/blackbass.png';
import brochet from 'src/assets/fishes/brochet.png';
import carpe from 'src/assets/fishes/carpe.png';
import cephalopode from 'src/assets/fishes/cephalopode.png';
import commune from 'src/assets/fishes/commune.png';
import congre from 'src/assets/fishes/congre.png';
import dorade from 'src/assets/fishes/dorade.png';
import espadon from 'src/assets/fishes/espadon.png';
import esturgeon from 'src/assets/fishes/esturgeon.png';
import gardon from 'src/assets/fishes/gardon.png';
import koi from 'src/assets/fishes/koi.png';
import lieu from 'src/assets/fishes/lieu.png';
import maquereau from 'src/assets/fishes/maquereau.png';
import mirroir from 'src/assets/fishes/mirroir.png';
import pagre from 'src/assets/fishes/pagre.png';
import perche from 'src/assets/fishes/perche.png';
import raie from 'src/assets/fishes/raie.png';
import rouget from 'src/assets/fishes/rouget.png';
import sandre from 'src/assets/fishes/sandre.png';
import silure from 'src/assets/fishes/silure.png';
import thon_blanc from 'src/assets/fishes/thon_blanc.png';
import thon_jaune from 'src/assets/fishes/thon_jaune.png';
import thon_rouge from 'src/assets/fishes/thon_rouge.png';
import truite from 'src/assets/fishes/truite.png';
import { Add, Clear } from '@mui/icons-material';
import { useAuthentication } from 'src/hooks/useAuthentication';
import { FishKind, FishKinds } from 'shared/types/FishKind';
import { Spot } from 'src/models/Spot';
import { useUpdateSpotFishesMutation } from 'src/services/spot.service';

const fishKindImage = (fishKind: FishKind) => {
  switch (fishKind) {
    case 'Bar':
      return bar;
    case 'Barbue':
      return barbue;
    case 'BlackBass':
      return blackbass;
    case 'Brochet':
      return brochet;
    case 'Carpe':
      return carpe;
    case 'Cephalopode':
      return cephalopode;
    case 'Commune':
      return commune;
    case 'Congre':
      return congre;
    case 'Dorade':
      return dorade;
    case 'Espadon':
      return espadon;
    case 'Esturgeon':
      return esturgeon;
    case 'Gardon':
      return gardon;
    case 'Koï':
      return koi;
    case 'Lieu':
      return lieu;
    case 'Maquereau':
      return maquereau;
    case 'Mirroir':
      return mirroir;
    case 'Pagre':
      return pagre;
    case 'Perche':
      return perche;
    case 'Raie':
      return raie;
    case 'Rouget':
      return rouget;
    case 'Sandre':
      return sandre;
    case 'Silure':
      return silure;
    case 'Thon blanc':
      return thon_blanc;
    case 'Thon jaune':
      return thon_jaune;
    case 'Thon rouge':
      return thon_rouge;
    case 'Truite':
      return truite;
  }
};

interface Props {
  spot: Spot;
}

const SpotFishes = ({ spot }: Props) => {
  const { isSpotOwner } = useAuthentication();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [updateFishes] = useUpdateSpotFishesMutation();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFishRemove = async (fish: FishKind) => {
    const fishes = [...spot.fishes];
    fishes.splice(fishes.indexOf(fish), 1);
    await updateFishes({ spotId: spot.id, fishes });
  };

  const handleFishAdd = async (fish: FishKind) => {
    await updateFishes({ spotId: spot.id, fishes: [...spot.fishes, fish] });
    setAnchorEl(null);
  };

  return (
    <Box sx={{ display: 'flex', paddingBottom: '2rem' }}>
      {spot.fishes.length > 0 && (
        <Stack
          direction="row"
          spacing="1rem"
          overflow="auto"
          sx={{ padding: '0.5rem', marginBottom: '1rem' }}
        >
          {spot.fishes.map((fish, index) => (
            <Card
              sx={{ minWidth: '150px', display: 'flex', boxShadow: 'none' }}
              key={`fish_${index}`}
            >
              <CardContent
                sx={{
                  display: 'flex',
                  textAlign: 'center',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flex: '1',
                  position: 'relative',
                  paddingY: '1.5rem',
                }}
              >
                <Box
                  component="img"
                  alt="logo"
                  src={fishKindImage(fish)}
                  maxHeight="50px"
                  maxWidth="118px"
                  height="fit-content"
                />
                {isSpotOwner(spot) && (
                  <IconButton
                    onClick={() => handleFishRemove(fish)}
                    sx={{
                      position: 'absolute',
                      top: '0.1rem',
                      right: 0,
                      padding: 0,
                    }}
                  >
                    <Clear />
                  </IconButton>
                )}
              </CardContent>
            </Card>
          ))}
        </Stack>
      )}

      {isSpotOwner(spot) && (
        <>
          {spot.fishes.length === 0 && (
            <Alert severity="info" sx={{ marginBottom: '1rem' }}>
              Beaucoup de pécheurs choisissent leur lieu de pêche en fonction
              des poissons disponibles à la pêche.
              <br />
              Ajoutez les espèces à pêcher avec le bouton ci-contre.
            </Alert>
          )}
          <Fab
            color="primary"
            aria-label="add"
            sx={{
              marginY: '1.5rem !important',
              marginLeft: '1.5rem !important',
              marginRight: '0.8rem !important',
              minWidth: '3.5rem',
            }}
            onClick={(event) => setAnchorEl(event.currentTarget)}
          >
            <Add />
          </Fab>
          <Menu
            anchorEl={anchorEl}
            open={open}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'left',
            }}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {FishKinds.filter((fish) => !spot.fishes.includes(fish)).map(
              (fish, index) => (
                <MenuItem
                  onClick={() => handleFishAdd(fish)}
                  key={`add_fish_${index}`}
                >
                  {fish}
                </MenuItem>
              )
            )}
          </Menu>
        </>
      )}
    </Box>
  );
};

export default SpotFishes;
