import config from '../utils/config';
import { CalendarEvent, DraftCalendarEvent } from '../models/CalendarEvent';
import { parseJSON } from 'date-fns';
import { EventKind } from 'shared/types/EventKind';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { withAuthHeader } from 'src/services/auth-headers';

export const calendarEventsApi = createApi({
  reducerPath: 'calendarEventsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.apiEndpoint}/api/calendar-events`,
    prepareHeaders: withAuthHeader,
  }),
  tagTypes: ['CalendarEvents'],
  endpoints: (builder) => ({
    findCalendarEvents: builder.query<
      CalendarEvent[],
      { spotId: string; from: Date; to: Date }
    >({
      query: ({ spotId, from, to }) =>
        `spots/${spotId}?from=${from.toJSON()}&to=${to.toJSON()}`,
      transformResponse: (response: CalendarEventJson[]) =>
        response.map((calendarEventJson) =>
          parseCalendarEvent(calendarEventJson)
        ),
      providesTags: () => ['CalendarEvents'],
    }),
    createCalendarEvent: builder.mutation<void, DraftCalendarEvent>({
      query: (draftCalendarEvent) => ({
        url: '',
        method: 'POST',
        body: draftCalendarEvent,
      }),
      invalidatesTags: ['CalendarEvents'],
    }),
    updateCalendarEvent: builder.mutation<void, CalendarEvent>({
      query: (calendarEvent) => ({
        url: calendarEvent.id,
        method: 'PUT',
        body: calendarEvent,
      }),
      invalidatesTags: ['CalendarEvents'],
    }),
    deleteCalendarEvent: builder.mutation<void, string>({
      query: (calendarEventId) => ({
        url: calendarEventId,
        method: 'DELETE',
      }),
      invalidatesTags: ['CalendarEvents'],
    }),
  }),
});

interface CalendarEventJson {
  id: string;
  spotId: string;
  start: string;
  end: string;
  kind: EventKind;
}

const parseCalendarEvent = (b: CalendarEventJson): CalendarEvent => ({
  ...b,
  start: parseJSON(b.start),
  end: parseJSON(b.end),
});

export const {
  useFindCalendarEventsQuery,
  useCreateCalendarEventMutation,
  useUpdateCalendarEventMutation,
  useDeleteCalendarEventMutation,
} = calendarEventsApi;
