import React, { ChangeEvent, FormEvent, useState } from 'react';
import * as yup from 'yup';
import { emailValidator, useForm } from 'src/hooks/useForm';
import {
  Alert,
  FormHelperText,
  IconButton,
  InputBase,
  Paper,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import prospectService from 'src/services/prospect.service';

interface Props {
  spotId?: string;
}

const ProspectForm = ({ spotId }: Props) => {
  const [email, setEmail] = useState('');
  const [registered, setRegistered] = useState<boolean>(false);

  const schema = yup.object().shape({
    email: emailValidator,
  });

  const { message, validate } = useForm(schema, {
    email,
  });

  const submitProspect = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await validate(() =>
      prospectService
        .registerProspect({ email, spotId })
        .then(() => setRegistered(true))
    );
  };

  return (
    <>
      {!registered ? (
        <>
          <Paper
            component="form"
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
            onSubmit={submitProspect}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              size="small"
              type="email"
              required
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setEmail(e.target.value)
              }
              placeholder="Votre email"
            />
            <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
              <SendIcon />
            </IconButton>
          </Paper>
          <FormHelperText error>{message('email')}</FormHelperText>
        </>
      ) : (
        <Alert severity="success">
          Nous vous remercions, nous vous tiendrons informé prochainement.
        </Alert>
      )}
    </>
  );
};

export default ProspectForm;
