import * as React from 'react';
import maplibregl from 'maplibre-gl';
import Map, {
  GeolocateControl,
  Marker,
  NavigationControl,
  ScaleControl,
  Popup,
} from 'react-map-gl/maplibre';
import { useFindSpotAnnouncementsQuery } from 'src/services/spot.service';
import { theme } from 'src/theme';
import { SpotAnnouncement } from 'src/models/SpotAnnouncement';
import SpotAnnouncementCard from 'src/components/SpotAnnouncementCard/SpotAnnouncementCard';

const SpotMap = () => {
  const [spotDetails, setSpotDetails] = React.useState<SpotAnnouncement>();

  const { data: spotAnnouncements } = useFindSpotAnnouncementsQuery({
    published: true,
  });

  return (
    <Map
      attributionControl
      id="spotMap"
      initialViewState={{
        latitude: 47,
        longitude: 2,
        zoom: 5,
      }}
      maxZoom={7}
      style={{
        minHeight: 'calc(100vh - 100px)',
        fontFamily: 'Marianne, sans-serif',
      }}
      mapLib={maplibregl}
      mapStyle="https://openmaptiles.geo.data.gouv.fr/styles/osm-bright/style.json"
      reuseMaps
    >
      <GeolocateControl position="top-left" />
      <NavigationControl position="top-left" />
      <ScaleControl />
      {spotAnnouncements
        ?.filter(
          (spotAnnouncement) =>
            spotAnnouncement.latitude && spotAnnouncement.longitude
        )
        .map((spotAnnouncement) => (
          <Marker
            key={spotAnnouncement.id}
            latitude={spotAnnouncement.latitude}
            longitude={spotAnnouncement.longitude}
            color={theme.palette.primary.main}
            anchor="bottom"
            onClick={(e) => {
              e.originalEvent.stopPropagation();
              setSpotDetails(spotAnnouncement);
            }}
          />
        ))}
      {spotDetails && (
        <Popup
          anchor="left"
          latitude={spotDetails.latitude}
          longitude={spotDetails.longitude}
          onClose={() => setSpotDetails(undefined)}
        >
          <SpotAnnouncementCard spotAnnouncement={spotDetails} />
        </Popup>
      )}
    </Map>
  );
};

export default SpotMap;
