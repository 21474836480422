import React, { useEffect, useState } from 'react';
import { SpotFile } from 'src/models/SpotFile';
import { FileKind } from 'shared/types/FileKind';
import { useAuthentication } from 'src/hooks/useAuthentication';
import {
  useCreateSpotFileMutation,
  useDeleteSpotFileMutation,
} from 'src/services/spotFile.service';
import { getTime } from 'date-fns';
import { Fab, Skeleton } from '@mui/material';
import SpotImageDialog from './SpotImageDialog';
import { Delete, PhotoCamera } from '@mui/icons-material';
import AppErrorSnackbar from '../AppErrorSnackbar/AppErrorSnackbar';
import { Spot } from 'src/models/Spot';

interface SpotImageProps {
  spotFile?: SpotFile;
  spot: Spot;
  fileKind: FileKind;
  height: number;
  isFetching: boolean;
}

const SpotImage = ({
  spotFile,
  spot,
  fileKind,
  height,
  isFetching,
}: SpotImageProps) => {
  const { isSpotOwner } = useAuthentication();
  const [
    createSpotFile,
    { isLoading: isCreateLoading, isError: isCreateError },
  ] = useCreateSpotFileMutation();
  const [
    deleteSpotFile,
    { isLoading: isDeleteLoading, isError: isDeleteError },
  ] = useDeleteSpotFileMutation();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [openImageDialog, setOpenImageDialog] = useState<boolean>(false);

  const [imgKey, setImgKey] = useState<number>(getTime(new Date()));

  useEffect(() => {
    setIsLoading(
      (isLoading && isFetching) || isCreateLoading || isDeleteLoading
    );
  }, [isFetching, isCreateLoading, isDeleteLoading]); //eslint-disable-line react-hooks/exhaustive-deps

  const handleAddingFile = async (event: any) => {
    const fileFormData: FormData = new FormData();
    fileFormData.set('file', event.target.files[0], event.target.files[0].name);
    await createSpotFile({ spotId: spot.id, fileKind, fileFormData });
  };

  const handleDeletingFile = async () => {
    await deleteSpotFile({ spotId: spot.id, fileKind });
  };

  return (
    <>
      <AppErrorSnackbar isLoading={isCreateLoading} isError={isCreateError} />
      <AppErrorSnackbar isLoading={isDeleteLoading} isError={isDeleteError} />
      {!spotFile ? (
        <>
          {(isSpotOwner(spot) || isLoading || isFetching) && (
            <Skeleton
              variant="rectangular"
              height={height}
              animation={(isLoading || isFetching) && 'wave'}
            />
          )}
        </>
      ) : (
        <>
          {isLoading && (
            <Skeleton
              variant="rectangular"
              height={height}
              animation={'wave'}
            />
          )}
          <img
            key={imgKey}
            src={spotFile.path}
            srcSet={spotFile.path}
            height={height}
            style={{
              width: '100%',
              objectFit: 'cover',
              verticalAlign: 'middle',
              cursor: 'pointer',
              borderRadius: '4px',
            }}
            hidden={isLoading}
            onLoad={() => {
              setIsLoading(false);
            }}
            onError={() => {
              setIsLoading(true);
              setTimeout(() => setImgKey(getTime(new Date())), 500);
            }}
            onClick={() => setOpenImageDialog(true)}
            alt="Vue du spot"
          />
          <SpotImageDialog
            spotFile={spotFile}
            open={openImageDialog}
            onClose={() => setOpenImageDialog(false)}
          />
        </>
      )}
      {isSpotOwner(spot) && !isLoading && (
        <Fab
          color="primary"
          sx={{
            position: 'absolute !important',
            bottom: '1rem',
            right: spotFile ? '5rem' : '1rem',
          }}
          component="label"
        >
          <PhotoCamera />
          <input
            hidden
            accept="image/*"
            type="file"
            onChange={handleAddingFile}
          />
        </Fab>
      )}
      {isSpotOwner(spot) && !isLoading && spotFile && (
        <Fab
          color="error"
          sx={{
            position: 'absolute !important',
            bottom: '1rem',
            right: '1rem',
          }}
          onClick={handleDeletingFile}
        >
          <Delete />
        </Fab>
      )}
    </>
  );
};

export default SpotImage;
