import React from 'react';
import { H2 } from 'src/components/Styled/Styled';
import { Alert, Divider, Typography } from '@mui/material';
import { RentalKind } from 'shared/types/RentalKind';
import ProspectForm from 'src/components/ProspectForm/ProspectForm';

interface Props {
  spotId: string;
  rentalKind: RentalKind;
}

const SpotBooking = ({ spotId, rentalKind }: Props) => {
  console.log('rentalKind', rentalKind);

  // const [booking, setBooking] = useState<Booking | undefined>();
  // const [to, setTo] = useState<Date | undefined>();
  // const [checkBooking, { error }] = useCheckBookingMutation();
  // const handleDateRangeChange = (dateRange: DateRange<Date>) => {
  //   setBooking(undefined);
  //   if (dateRange[0] && dateRange[1]) {
  //     checkBooking({
  //       spotId,
  //       start: dateRange[0],
  //       end: dateRange[1],
  //     })
  //       .unwrap()
  //       .then((booking) => {
  //         setBooking(booking);
  //       });
  //   }
  // };

  return (
    <>
      <H2>Réservation</H2>
      <Divider sx={{ marginY: '1rem' }} />
      <Alert severity="info" sx={{ marginY: '2rem' }}>
        <Typography component="div">
          Pour rencontrer l'hôte, ca se passe ici !
          <br />
          <br />
          Laissez votre mail pour rentrer en contact avec l'hôte
          <ProspectForm spotId={spotId} />
        </Typography>
      </Alert>
      {/*<PublicCalendar*/}
      {/*  spotId={spotId}*/}
      {/*  rentalKind={rentalKind}*/}
      {/*  onAccept={handleDateRangeChange}*/}
      {/*/>*/}
      {/*{error && (*/}
      {/*  <Alert severity="error">*/}
      {/*    {(error as any).data.name === 'OverlappingDateRangesError'*/}
      {/*      ? 'Dates indisponibles'*/}
      {/*      : "Une erreur s'est produite"}*/}
      {/*  </Alert>*/}
      {/*)}*/}
      {/*<Button*/}
      {/*  variant="contained"*/}
      {/*  onClick={() => {}}*/}
      {/*  sx={{ marginTop: '1rem' }}*/}
      {/*  fullWidth*/}
      {/*>*/}
      {/*  Réserver*/}
      {/*</Button>*/}
      {/*{booking && (*/}
      {/*  <>*/}
      {/*    <Grid container paddingY="2rem" paddingX="1rem">*/}
      {/*      <Grid*/}
      {/*        item*/}
      {/*        xs={6}*/}
      {/*        sx={{ textDecoration: 'underline', paddingY: '0.5rem' }}*/}
      {/*      >*/}
      {/*        {booking.dayCount}{' '}*/}
      {/*        {pluralize(booking.dayCount)(*/}
      {/*          rentalKind === 'Day' ? 'jour' : 'nuit'*/}
      {/*        )}*/}
      {/*      </Grid>*/}
      {/*      <Grid item xs={6} textAlign="right">*/}
      {/*        {booking.totalPrice} €*/}
      {/*      </Grid>*/}
      {/*      <Grid*/}
      {/*        item*/}
      {/*        xs={6}*/}
      {/*        sx={{ textDecoration: 'underline', paddingY: '0.5rem' }}*/}
      {/*      >*/}
      {/*        Frais de dossier (17%)*/}
      {/*      </Grid>*/}
      {/*      <Grid item xs={6} textAlign="right">*/}
      {/*        {booking.totalPrice} €*/}
      {/*      </Grid>*/}
      {/*      <Grid*/}
      {/*        item*/}
      {/*        xs={6}*/}
      {/*        sx={{ textDecoration: 'underline', paddingY: '0.5rem' }}*/}
      {/*      >*/}
      {/*        Taxes*/}
      {/*      </Grid>*/}
      {/*      <Grid item xs={6} textAlign="right">*/}
      {/*        {booking.totalPrice} €*/}
      {/*      </Grid>*/}
      {/*    </Grid>*/}
      {/*    <Divider />*/}
      {/*    <Grid container paddingTop="2rem" paddingX="1rem">*/}
      {/*      <Grid*/}
      {/*        item*/}
      {/*        xs={6}*/}
      {/*        sx={{*/}
      {/*          textDecoration: 'underline',*/}
      {/*          paddingY: '0.25rem',*/}
      {/*          fontWeight: 'bold',*/}
      {/*        }}*/}
      {/*      >*/}
      {/*        Total*/}
      {/*      </Grid>*/}
      {/*      <Grid item xs={6} textAlign="right" fontWeight="bold">*/}
      {/*        {booking.totalPrice} €*/}
      {/*      </Grid>*/}
      {/*    </Grid>*/}
      {/*  </>*/}
      {/*)}*/}
    </>
  );
};

export default SpotBooking;
