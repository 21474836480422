import { configureStore } from '@reduxjs/toolkit';
import authSlice from './reducers/authSlice';
import { calendarEventsApi } from '../services/calendar-events.service';
import { spotApi } from '../services/spot.service';
import { spotFilesApi } from '../services/spotFile.service';
import { pricesApi } from 'src/services/price.service';
import { accountApi } from 'src/services/account.service';
import { bookingsApi } from 'src/services/booking.service';

export const applicationReducer = {
  [authSlice.name]: authSlice.reducer,
  [spotApi.reducerPath]: spotApi.reducer,
  [calendarEventsApi.reducerPath]: calendarEventsApi.reducer,
  [spotFilesApi.reducerPath]: spotFilesApi.reducer,
  [pricesApi.reducerPath]: pricesApi.reducer,
  [accountApi.reducerPath]: accountApi.reducer,
  [bookingsApi.reducerPath]: bookingsApi.reducer,
};

export const store = configureStore({
  reducer: applicationReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      spotApi.middleware,
      calendarEventsApi.middleware,
      spotFilesApi.middleware,
      pricesApi.middleware,
      accountApi.middleware,
      bookingsApi.middleware
    ),
});

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
