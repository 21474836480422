import { PriceDuration } from 'shared/types/PriceDuration';
import { RentalKind } from 'shared/types/RentalKind';

export interface Price {
  value: number;
  duration: PriceDuration;
}

export const durationLabel = (
  rentalKind: RentalKind,
  duration?: PriceDuration
) => {
  if (duration) {
    switch (duration) {
      case '12 hours':
        return 'demi-journée';
      case '1 day':
        return rentalKind === 'Day' ? 'par jour' : 'par nuit';
      case '2 days':
        return rentalKind === 'Day' ? 'pour deux jours' : 'pour deux nuits';
      case '7 days':
        return rentalKind === 'Day' ? 'pour sept jours' : 'par semaine';
    }
  }
};
