import React from 'react';
import { H1 } from 'src/components/Styled/Styled';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

const FAQ = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
      }}
    >
      <H1 sx={{ marginBottom: '1rem' }}>Questions fréquentes</H1>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography fontWeight="bold">Comment réserver ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Pour réserver, il vous suffit de vous rendre sur le site de l'hôte
            et de le contacter par e-mail ou par téléphone. Une fois votre
            demande envoyée, l'hôte concerné se chargera de vous recontacter.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography fontWeight="bold">
            Est-ce qu'il faut payer en ligne ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Non, le paiement en ligne n'est pas obligatoire. Toutefois, certains
            propriétaires peuvent demander un acompte pour garantir la
            réservation. Dans ce cas, les modalités de paiement vous seront
            précisées et vous pourrez en discuter avec l'hôte une fois votre
            demande de réservation acceptée.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography fontWeight="bold">
            Quand arriver sur les lieux ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Les heures d'arrivée varient selon les lieux de réservation. En
            général, vous pouvez arriver sur les lieux le matin ou l'après-midi,
            mais chaque lieu est différent. L'hôte concerné vous précisera les
            horaires d'arrivée dans les détails de son annonce ou vous pourrez
            en discuter avec lui directement.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography fontWeight="bold">
            Peut-on avoir des accompagnants ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Oui, certains hôtes acceptent les accompagnants sous certaines
            conditions. Les détails seront précisés dans l'annonce de l'hôte ou
            vous pourrez en discuter avec lui directement.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography fontWeight="bold">
            Que faire en cas d'annulation ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            En cas d'annulation, vous devez prévenir l'hôte le plus tôt
            possible. Les conditions d'annulation peuvent varier selon les
            hôtes, il est donc important de les vérifier avant de réserver. Si
            vous avez payé un acompte, les modalités de remboursement seront
            précisées dans les conditions d'annulation de l'hôte.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default FAQ;
