import React, { useState } from 'react';
import {
  Alert,
  Autocomplete,
  Button,
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
} from '@mui/material';
import EquipmentIcon from 'src/components/EquipmentIcon/EquipmentIcon';
import { Spot } from 'src/models/Spot';
import { Clear } from '@mui/icons-material';
import { Equipment, EquipmentTypes } from 'shared/types/Equipment';

export interface Props {
  spot: Spot;
  open: boolean;
  onSubmit: (equipments: Equipment[]) => void;
  onClose: () => void;
}

function EquipmentsDialog({ spot, onClose, onSubmit, open }: Props) {
  const [equipments, setEquipments] = useState(spot.equipments);
  const [newEquipment, setNewEquipment] = React.useState<Equipment | null>(
    null
  );

  const filter = createFilterOptions<Equipment>();

  const removeEquipment = (index: number) => {
    const newEquipments = [...equipments];
    newEquipments.splice(index, 1);
    setEquipments(newEquipments);
  };

  const addEquipment = (equipment: Equipment | null) => {
    if (equipment?.length) {
      setEquipments([...equipments, equipment.replace('Ajouter ', '')]);
      setNewEquipment(equipment);
      setTimeout(() => setNewEquipment(''));
    }
  };

  return (
    <Dialog onClose={onClose} open={open} scroll="paper" disableScrollLock>
      <DialogTitle>
        Lister vos équipements disponibles
        <Alert severity="info" sx={{ marginY: '1rem' }}>
          Indiquez et organisez les équipements à disposition. Plus il seront
          nombreux et précis plus vous avez de chance d’être contacté pour une
          réservation.
        </Alert>
        <Autocomplete
          value={newEquipment}
          onChange={(_, newEquipment) => {
            addEquipment(newEquipment);
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            const { inputValue } = params;
            if (
              inputValue !== '' &&
              !options.some((option) => inputValue === option)
            ) {
              filtered.push(`Ajouter ${inputValue}`);
            }
            return filtered;
          }}
          selectOnFocus
          clearOnBlur
          clearOnEscape
          handleHomeEndKeys
          options={EquipmentTypes.filter((_) => !equipments.includes(_))}
          getOptionLabel={(option) => option}
          renderOption={(props, option) => <li {...props}>{option}</li>}
          freeSolo
          renderInput={(params) => (
            <TextField
              {...params}
              label="Sélectionnez un équipement pour l'ajouter"
            />
          )}
        />
      </DialogTitle>

      <DialogContent>
        <List>
          {equipments.map((equipment, index) => (
            <ListItem
              key={'equipment_' + index}
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => removeEquipment(index)}
                >
                  <Clear />
                </IconButton>
              }
            >
              <ListItemIcon>
                <EquipmentIcon equipment={equipment} />
              </ListItemIcon>
              <ListItemText>{equipment}</ListItemText>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Annuler
        </Button>
        <Button variant="contained" onClick={() => onSubmit(equipments)}>
          Enregistrer les modifications
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EquipmentsDialog;
