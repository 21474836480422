import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { Spot } from 'src/models/Spot';
import { Block, CheckCircle, Checklist, ExpandMore } from '@mui/icons-material';
import { Requirement, RequirementTypes } from 'shared/types/Requirement';
import AppSelectList from 'src/components/AppSelectList/AppSelectList';
import { Prohibition, ProhibitionTypes } from 'shared/types/Prohibition';
import { Permission, PermissionTypes } from 'shared/types/Permission';
import AppWithIcon from 'src/components/AppWithIcon/AppWithIcon';

export interface Props {
  spot: Spot;
  open: boolean;
  onSubmit: (
    requirements: Requirement[],
    prohibitions: Prohibition[],
    permissions: Permission[]
  ) => void;
  onClose: () => void;
}

function RulesDialog({ spot, onClose, onSubmit, open }: Props) {
  const [requirements, setRequirements] = useState(spot.requirements);
  const [prohibitions, setProhibitions] = useState(spot.prohibitions);
  const [permissions, setPermissions] = useState(spot.permissions);

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Dialog onClose={onClose} open={open} scroll="paper" disableScrollLock>
      <DialogTitle>
        Détaillez votre réglement
        <Alert severity="info" sx={{ marginY: '1rem' }}>
          Listez ici l'ensemble des points spécifiques à votre réglement :
          obligations, autorisations et interdictions.
        </Alert>
      </DialogTitle>

      <DialogContent>
        <Accordion
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <AppWithIcon icon={<Checklist />}>
              <Typography fontWeight="bold">Obligations</Typography>
            </AppWithIcon>
          </AccordionSummary>
          <AccordionDetails>
            <AppSelectList
              items={requirements}
              defaultItems={RequirementTypes}
              inputLabel="Sélectionnez une obligation pour l'ajouter"
              onChange={(items) => setRequirements(items)}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel2'}
          onChange={handleChange('panel2')}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <AppWithIcon icon={<CheckCircle color="success" />}>
              <Typography fontWeight="bold">Autorisations</Typography>
            </AppWithIcon>
          </AccordionSummary>
          <AccordionDetails>
            <AppSelectList
              items={permissions}
              defaultItems={PermissionTypes}
              inputLabel="Sélectionnez une autorisation pour l'ajouter"
              onChange={(items) => setPermissions(items)}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel3'}
          onChange={handleChange('panel3')}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <AppWithIcon icon={<Block color="error" />}>
              <Typography fontWeight="bold">Interdictions</Typography>
            </AppWithIcon>
          </AccordionSummary>
          <AccordionDetails>
            <AppSelectList
              items={prohibitions}
              defaultItems={ProhibitionTypes}
              inputLabel="Sélectionnez une interdiction pour l'ajouter"
              onChange={(items) => setProhibitions(items)}
            />
          </AccordionDetails>
        </Accordion>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Annuler
        </Button>
        <Button
          variant="contained"
          onClick={() => onSubmit(requirements, prohibitions, permissions)}
        >
          Enregistrer les modifications
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default RulesDialog;
