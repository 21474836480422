import { EventKind } from 'shared/types/EventKind';
import { Brand } from 'shared/constants';

export interface CalendarEvent {
  id: string;
  spotId: string;
  start: Date;
  end: Date;
  kind: EventKind;
}

export type DraftCalendarEvent = Omit<CalendarEvent, 'id'>;

export const isCalendarEvent = (
  event: CalendarEvent | { start: Date; end: Date }
): event is CalendarEvent => {
  return (event as CalendarEvent).id !== undefined;
};

export const eventKindLabel = (eventKind: EventKind) => {
  switch (eventKind) {
    case 'Unavailable':
      return 'Indisponible';
    case 'Rental':
      return `Location directe (hors ${Brand})`;
    case 'Other':
      return 'Autre';
  }
};
