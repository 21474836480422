export type Prohibition =
  | 'Bateau pneumatique'
  | 'Moteur électrique'
  | 'Bateau amorçeur'
  | 'Esches animal'
  | 'Tresse'
  | 'Bas de ligne acier'
  | 'Appareils musicaux'
  | 'Baignade'
  | 'Barbecue'
  | 'Biwy'
  | 'Camping car'
  | 'Sac de conservation poisson'
  | 'Hameçons fast-grip et Bent’hook'
  | 'Hameçons avec ardillon'
  | 'Canons à bouillette'
  | 'Pêche au carnassier'
  | 'Pêche à la carpe'
  | 'Pêche au mort manié'
  | "Etat d'ébriété"
  | 'Pêche à la traine en bâteau'
  | 'Pêche au vif'
  | string;

export const ProhibitionTypes: Prohibition[] = [
  'Bateau pneumatique',
  'Moteur électrique',
  'Bateau amorçeur',
  'Esches animal',
  'Tresse',
  'Bas de ligne acier',
  'Appareils musicaux',
  'Baignade',
  'Barbecue',
  'Biwy',
  'Camping car',
  'Sac de conservation poisson',
  'Hameçons fast-grip et Bent’hook',
  'Hameçons avec ardillon',
  'Canons à bouillette',
  'Pêche au carnassier',
  'Pêche à la carpe',
  'Pêche au mort manié',
  "Etat d'ébriété",
  'Pêche à la traine en bâteau',
  'Pêche au vif',
];
