export type Requirement =
  | '6 cannes maximum'
  | '2 cannes par pêcheur'
  | '3 cannes par pêcheur'
  | '4 cannes par pêcheur'
  | 'Graines cuites'
  | 'Bas de ligne acier'
  | 'Tapis de réception'
  | 'Epuisette'
  | 'Carte de pêche'
  | '2 pêcheurs par poste'
  | 'No kill'
  | 'Prise (truite)'
  | 'Accompte'
  | 'Produit désinfectant pour poisson'
  | 'Corps de ligne en nylon'
  | 'Hameçons sans ardillons'
  | 'Bas de ligne en acier ou fluoro'
  | '70/100 minimum'
  | 'Gilet de sauvetage sur bateau'
  | 'Bouillette'
  | 'Pelet'
  | string;

export const RequirementTypes: Requirement[] = [
  '6 cannes maximum',
  '2 cannes par pêcheur',
  '3 cannes par pêcheur',
  '4 cannes par pêcheur',
  'Graines cuites',
  'Bas de ligne acier',
  'Tapis de réception',
  'Epuisette',
  'Carte de pêche',
  '2 pêcheurs par poste',
  'No kill',
  'Prise (truite)',
  'Accompte',
  'Produit désinfectant pour poisson',
  'Corps de ligne en nylon',
  'Hameçons sans ardillons',
  'Bas de ligne en acier ou fluoro',
  '70/100 minimum',
  'Gilet de sauvetage sur bateau',
  'Bouillette',
  'Pelet',
];
