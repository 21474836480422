import React, { useState } from 'react';
import { Button, Container } from '@mui/material';
import { useAuthentication } from 'src/hooks/useAuthentication';
import { useDocumentTitle } from 'src/hooks/useDocumentTitle';
import { H1 } from 'src/components/Styled/Styled';
import SpotAnnouncementList from 'src/components/SpotAnnouncementList/SpotAnnouncementList';
import { Add } from '@mui/icons-material';
import AppDialog from 'src/components/AppDialog/AppDialog';
import NewSpotForm from 'src/views/UserAnnouncementView/NewSpotForm';

// interface Props {}

const UserAnnouncementView = () => {
  useDocumentTitle('Mes annonces');
  const [dialogOpen, setDialogOpen] = useState(false);

  const { isAdmin, hasRole } = useAuthentication();

  return (
    <Container maxWidth="xl">
      <H1>Mes annonces</H1>
      <Button
        variant="contained"
        startIcon={<Add />}
        sx={{ marginTop: '1rem' }}
        onClick={() => {
          setDialogOpen(true);
        }}
      >
        Ajouter
      </Button>
      <AppDialog
        title="Nouvelle annonce"
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        content={
          <NewSpotForm
            onCancel={() => setDialogOpen(false)}
            onNavigate={() => setDialogOpen(false)}
          />
        }
      />

      {(hasRole('PondOwner') || isAdmin) && (
        <SpotAnnouncementList
          title="Lieux de pêche"
          spotKind="Pond"
          extraInfos={['IsPublished']}
        />
      )}
      {(hasRole('Guide') || isAdmin) && (
        <SpotAnnouncementList
          title="Guidage"
          spotKind="Guide"
          extraInfos={['IsPublished']}
        />
      )}
    </Container>
  );
};

export default UserAnnouncementView;
