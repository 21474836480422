export type RentalKind = 'Day' | 'Night';

export const RentalKinds: RentalKind[] = ['Day', 'Night'];

export const rentalStartTimeShift = (rentalKind: RentalKind) => {
  switch (rentalKind) {
    case 'Day':
      return 0;
    case 'Night':
      return 12;
  }
};
export const rentalEndTimeShift = (rentalKind: RentalKind) => {
  switch (rentalKind) {
    case 'Day':
      return 24;
    case 'Night':
      return 12;
  }
};
