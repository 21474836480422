export type Equipment =
  | 'Produit à main'
  | 'WC'
  | 'Douche'
  | 'Lavabo'
  | 'Barbecue'
  | 'Frigo'
  | 'Congélateur'
  | 'Cottage'
  | 'Biwi'
  | 'Réseaux 3G/4G'
  | 'Wifi'
  | 'Location de barque'
  | 'Location de vélos'
  | "Parking à l'entrée"
  | 'Parking au poste'
  | 'Restauration'
  | 'Boissons chaudes'
  | string;

export const EquipmentTypes: Equipment[] = [
  'Produit à main',
  'WC',
  'Douche',
  'Lavabo',
  'Barbecue',
  'Frigo',
  'Congélateur',
  'Cottage',
  'Biwi',
  'Réseaux 3G/4G',
  'Wifi',
  'Location de barque',
  'Location de vélos',
  "Parking à l'entrée",
  'Parking au poste',
  'Restauration',
  'Boissons chaudes',
];
