import React, { useMemo, useState } from 'react';
import { H1 } from '../Styled/Styled';
import AppWithIcon from '../AppWithIcon/AppWithIcon';
import { IconButton, Typography } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { Spot } from 'src/models/Spot';
import { useAuthentication } from 'src/hooks/useAuthentication';
import { useUpdateSpotLocationMutation } from 'src/services/spot.service';
import { findDepartment } from 'shared/models/Department';
import LocationDialog from 'src/components/SpotLocation/LocationDialog';

interface Props {
  spot: Spot;
}

const SpotLocation = ({ spot }: Props) => {
  const { isSpotOwner } = useAuthentication();
  const [dialogOpen, setDialogOpen] = useState(false);

  const [updateLocation] = useUpdateSpotLocationMutation();
  const submitLocation = async (
    name: string,
    location: string,
    department: string
  ) => {
    await updateLocation({ spotId: spot.id, name, location, department });
    setDialogOpen(false);
  };

  const department = useMemo(
    () => findDepartment(spot.department),
    [spot.department]
  );

  return (
    <>
      <H1>
        {isSpotOwner(spot) ? (
          <>
            <AppWithIcon
              icon={
                <IconButton
                  color="primary"
                  sx={{ paddingBottom: '0.25rem' }}
                  onClick={() => setDialogOpen(true)}
                >
                  <Edit />
                </IconButton>
              }
              iconPosition="right"
            >
              {spot.name}
            </AppWithIcon>
            <LocationDialog
              spot={spot}
              open={dialogOpen}
              onClose={() => setDialogOpen(false)}
              onSubmit={submitLocation}
            />
          </>
        ) : (
          <div>{spot.name}</div>
        )}
        {spot.location}
      </H1>
      <Typography
        variant="body2"
        color="text.secondary"
        fontWeight="500"
        sx={{ paddingBottom: '1rem' }}
      >
        {department && (
          <i>
            {department.name} ({department.code})
          </i>
        )}
      </Typography>
    </>
  );
};

export default SpotLocation;
