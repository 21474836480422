import React, { useState } from 'react';
import {
  AppBar,
  Box,
  Button,
  Container,
  Link,
  Toolbar,
  Typography,
} from '@mui/material';
import logo from '../../assets/logo.svg';
import AccountMenu from 'src/components/Account/AccountMenu/AccountMenu';
import { useAuthentication } from 'src/hooks/useAuthentication';
import { Brand } from 'shared/constants';
import AppDialog from 'src/components/AppDialog/AppDialog';
import AccountSignupForm from 'src/components/Account/AccountSignupForm/AccountSignupForm';
import { AccountCircleOutlined } from '@mui/icons-material';
import AccountSigninForm from 'src/components/Account/AccountSigninForm/AccountSigninForm';
import HeaderMenu from 'src/components/Header/HeaderMenu';
import HeaderLink from 'src/components/Header/HeaderLink';

function Header() {
  const { isAuthenticated } = useAuthentication();
  const [signinDialogOpen, setSigninDialogOpen] = useState(false);
  const [signupDialogOpen, setSignupDialogOpen] = useState(false);
  return (
    <AppBar position="fixed" color="secondary">
      <Toolbar sx={{ padding: 0 }}>
        <Container maxWidth="xl" sx={{ display: 'flex', alignItems: 'center' }}>
          <HeaderMenu />
          <Link
            href="/"
            sx={{
              display: { xs: 'none', md: 'flex' },
            }}
          >
            <Box
              component="img"
              alt="logo"
              src={logo}
              maxHeight={{ xs: '30px', sm: '140px' }}
            />
          </Link>
          <Typography
            component="span"
            sx={{
              flexGrow: { xs: 1, sm: 'unset' },
              fontSize: { xs: 20, sm: 25 },
              fontWeight: 'bold',
              marginBottom: '2px',
              marginLeft: { sm: '-10px' },
              marginRight: { sm: '3rem' },
              textAlign: { xs: 'center', sm: 'left' },
            }}
            data-testid="branding-typography"
          >
            {Brand}
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'none', md: 'flex' },
            }}
          >
            <HeaderLink to="lieux-de-peche" value="Lieux de pêche" />
            <HeaderLink to="guides" value="Guides" />
            <HeaderLink
              to="objectifs"
              value="Pourquoi faire ?"
              scrollOptions={{ offset: -40 }}
            />
          </Box>
          {isAuthenticated ? (
            <AccountMenu />
          ) : (
            <>
              <Box
                sx={{
                  display: { xs: 'none', md: 'flex' },
                }}
              >
                <Button
                  variant="contained"
                  sx={{ marginRight: '1rem' }}
                  onClick={() => setSignupDialogOpen(true)}
                >
                  S'inscrire
                </Button>
                <AppDialog
                  title={`Créez votre compte sur ${Brand}`}
                  open={signupDialogOpen}
                  onClose={() => setSignupDialogOpen(false)}
                  content={
                    <AccountSignupForm
                      onNavigate={() => setSignupDialogOpen(false)}
                    />
                  }
                />
                <Button
                  color="primary"
                  variant="outlined"
                  endIcon={<AccountCircleOutlined />}
                  onClick={() => setSigninDialogOpen(true)}
                >
                  Se connecter
                </Button>
                <AppDialog
                  title={`Connectez-vous sur ${Brand}`}
                  open={signinDialogOpen}
                  onClose={() => setSigninDialogOpen(false)}
                  content={
                    <AccountSigninForm
                      onNavigate={() => setSigninDialogOpen(false)}
                      onConnect={() => setSigninDialogOpen(false)}
                    />
                  }
                />
              </Box>
              <Box
                sx={{
                  display: { xs: 'flex', md: 'none' },
                }}
              >
                <AccountMenu />
              </Box>
            </>
          )}
        </Container>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
