import React, { ChangeEvent, useState } from 'react';
import {
  Button,
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
} from '@mui/material';
import { Spot } from 'src/models/Spot';
import { useAuthentication } from 'src/hooks/useAuthentication';
import {
  useDeleteSpotMutation,
  useUpdateSpotPublicationMutation,
} from 'src/services/spot.service';
import RemoveDialog from 'src/components/SpotPublication/RemoveDialog';
import { useNavigate } from 'react-router-dom';

interface Props {
  spot: Spot;
}

const SpotPublication = ({ spot }: Props) => {
  const navigate = useNavigate();
  const { isSpotOwner, isAdmin } = useAuthentication();

  const [updatePublication] = useUpdateSpotPublicationMutation();
  const [deleteSpot] = useDeleteSpotMutation();

  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);

  const handleSwitchChange = async (e: ChangeEvent<HTMLInputElement>) => {
    await updatePublication({ spotId: spot.id, published: e.target.checked });
  };

  const handleRemoveSpot = async () => {
    await deleteSpot(spot.id);
    navigate('/mes-annonces');
  };

  if (!isSpotOwner(spot)) {
    return <></>;
  }

  return (
    <>
      <FormGroup sx={{ alignItems: 'end' }}>
        <FormControlLabel
          control={
            isAdmin ? (
              <Switch
                value={spot.published}
                defaultChecked={spot.published}
                onChange={handleSwitchChange}
                color="success"
              />
            ) : (
              <></>
            )
          }
          label={
            <Typography
              color={spot.published ? 'success.main' : 'grey'}
              sx={{ fontWeight: 'bold', fontSize: '1.25rem' }}
            >
              {spot.published ? 'Annonce publiée' : 'Annonce non publiée'}
            </Typography>
          }
        />
      </FormGroup>
      {isAdmin && (
        <>
          <Button
            variant="contained"
            color="error"
            sx={{ float: 'right' }}
            onClick={() => setRemoveDialogOpen(true)}
          >
            Supprimer l'annonce
          </Button>

          <RemoveDialog
            open={removeDialogOpen}
            onClose={() => setRemoveDialogOpen(false)}
            onSubmit={handleRemoveSpot}
          />
        </>
      )}
    </>
  );
};

export default SpotPublication;
