import React, { useEffect } from 'react';
import './App.scss';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import HomeView from './views/HomeView/HomeView';
import { Box, ThemeProvider } from '@mui/material';
import { store } from './store/store';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga4';
import config from './utils/config';
import { theme } from './theme';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { fr } from 'date-fns/locale';
import SignupView from 'src/views/SignupView/SignupView';
import SigninView from 'src/views/SigninView/SigninView';
import AccountView from 'src/views/AccountView/AccountView';
import { useAuthentication } from 'src/hooks/useAuthentication';
import ResetPasswordView from 'src/views/ResetPasswordView/ResetPasswordView';
import UserAnnouncementView from 'src/views/UserAnnouncementView/UserAnnouncementView';
import FetchInterceptor from 'src/components/FetchInterceptor/FetchInterceptor';
import SpotView from 'src/views/SpotView/SpotView';
import TermsOfUseView from 'src/views/TermsOfUseView/TermsOfUseView';

function AppWrapper() {
  if (config.googleAnalyticsTrackingCode) {
    ReactGA.initialize(config.googleAnalyticsTrackingCode);
  }

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
          <App />
        </LocalizationProvider>
      </Provider>
    </ThemeProvider>
  );
}

function App() {
  const location = useLocation();
  const { isAuthenticated, hasAnnouncementPermission } = useAuthentication();

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + location.search,
      title: location.pathname,
    });
  }, [location]);

  FetchInterceptor();

  return (
    <React.Suspense fallback={<></>}>
      <Header />
      <Box
        sx={{
          position: 'absolute',
          top: { xs: 56, md: 64 },
          width: '100%',
          paddingBottom: '3rem',
        }}
      >
        <Routes>
          <Route path="/annonces" element={<SpotView />} />
          <Route
            path="/conditions-generales-utilisation"
            element={<TermsOfUseView />}
          />
          {isAuthenticated && [
            <Route
              path="/mon-compte"
              element={<AccountView />}
              key="account_route"
            />,
          ]}
          {isAuthenticated &&
            hasAnnouncementPermission && [
              <Route
                path="/mes-annonces"
                element={<UserAnnouncementView />}
                key="announcement_route"
              />,
            ]}
          {!isAuthenticated && [
            <Route
              path="/inscription"
              element={<SignupView />}
              key="signup_route"
            />,
            <Route
              path="/connexion"
              element={<SigninView />}
              key="signin_route"
            />,
            <Route
              path="/mot-de-passe-oublie"
              element={<ResetPasswordView />}
              key="reset_password_route"
            />,
          ]}
          <Route path="/" element={<HomeView />} />
          <Route path="/*" element={<Navigate replace to="/" />} />
        </Routes>
      </Box>
      <Footer />
    </React.Suspense>
  );
}

export default AppWrapper;
