import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material';
import EquipmentIcon from 'src/components/EquipmentIcon/EquipmentIcon';
import { Spot } from 'src/models/Spot';
import { ArrowForwardIos, Edit } from '@mui/icons-material';
import AppWithIcon from '../AppWithIcon/AppWithIcon';
import { theme } from 'src/theme';
import EquipmentsDialog from './EquipmentsDialog';
import { Equipment } from 'shared/types/Equipment';
import { useAuthentication } from 'src/hooks/useAuthentication';
import { useUpdateSpotEquipmentsMutation } from 'src/services/spot.service';
import { H2 } from '../Styled/Styled';

interface Props {
  spot: Spot;
}

const SpotEquipments = ({ spot }: Props) => {
  const { isSpotOwner } = useAuthentication();
  const collapsableLength = useMediaQuery(theme.breakpoints.up('md')) ? 10 : 5;
  const [collapsed, setCollapsed] = useState(
    spot.equipments.length > collapsableLength
  );
  const [dialogOpen, setDialogOpen] = useState(false);

  const [updateEquipments] = useUpdateSpotEquipmentsMutation();
  const submitEquipments = async (equipments: Equipment[]) => {
    await updateEquipments({ spotId: spot.id, equipments });
    setDialogOpen(false);
  };

  return (
    <Card sx={{ marginBottom: '2rem' }}>
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          <H2>Equipements</H2>
          {isSpotOwner(spot) && (
            <>
              <Button
                variant="contained"
                startIcon={<Edit />}
                onClick={() => setDialogOpen(true)}
              >
                Modifier
              </Button>

              <EquipmentsDialog
                spot={spot}
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                onSubmit={submitEquipments}
              />
            </>
          )}
        </Box>

        <Divider sx={{ marginY: '1rem' }} />
        <Grid container spacing={2}>
          {spot.equipments
            ?.filter((_, index) => !collapsed || index < collapsableLength)
            .map((equipement, index) => (
              <Grid item xs={12} md={6} key={'equipment_' + index}>
                <Typography variant="body1" component="div">
                  <AppWithIcon icon={<EquipmentIcon equipment={equipement} />}>
                    {equipement}
                  </AppWithIcon>
                </Typography>
              </Grid>
            ))}
        </Grid>
        {collapsed && spot.equipments.length >= collapsableLength && (
          <Button
            variant="outlined"
            endIcon={<ArrowForwardIos fontSize="small" />}
            onClick={() => setCollapsed(false)}
            sx={{ marginTop: '1rem' }}
          >
            Voir tous les équipements
          </Button>
        )}
      </CardContent>
    </Card>
  );
};

export default SpotEquipments;
