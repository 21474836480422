import React, { useState } from 'react';
import { H2 } from '../Styled/Styled';
import { Alert, Button, Divider, Typography } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { useAuthentication } from 'src/hooks/useAuthentication';
import {
  useFindSpotPricesQuery,
  useUpdateSpotPricesMutation,
} from 'src/services/price.service';
import { durationLabel, Price } from 'src/models/Price';
import AppWithIcon from 'src/components/AppWithIcon/AppWithIcon';
import PriceDialog from 'src/components/SpotPrice/PriceDialog';
import { Spot } from 'src/models/Spot';

interface Props {
  spot: Spot;
}

const SpotPrice = ({ spot }: Props) => {
  const { isSpotOwner } = useAuthentication();
  const [dialogOpen, setDialogOpen] = useState(false);

  const { data: prices } = useFindSpotPricesQuery(spot.id);
  const [updatePrices] = useUpdateSpotPricesMutation();
  const submitPrices = async (prices: Price[]) => {
    await updatePrices({ spotId: spot.id, prices });
    setDialogOpen(false);
  };

  return (
    <>
      <H2>
        <AppWithIcon
          icon={
            isSpotOwner(spot) ? (
              <>
                <Button
                  variant="contained"
                  startIcon={<Edit />}
                  onClick={() => setDialogOpen(true)}
                >
                  Modifier
                </Button>
                {dialogOpen && (
                  <PriceDialog
                    rentalKind={spot.rentalKind}
                    prices={prices}
                    open={dialogOpen}
                    onClose={() => setDialogOpen(false)}
                    onSubmit={submitPrices}
                  />
                )}
              </>
            ) : (
              <></>
            )
          }
          iconPosition="float-right"
        >
          Tarifs
        </AppWithIcon>
      </H2>

      <Divider sx={{ marginY: '1rem' }} />
      {prices?.map((price) => (
        <Typography key={`price_${price.duration}`}>
          <b>{price.value}€</b> {durationLabel(spot.rentalKind, price.duration)}
        </Typography>
      ))}
      {(prices === undefined || prices.length === 0) && (
        <Alert severity="error">Tarif non renseigné</Alert>
      )}
    </>
  );
};

export default SpotPrice;
