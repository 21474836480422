import React from 'react';
import { Button } from '@mui/material';
import { scroller } from 'react-scroll';
import { useNavigate } from 'react-router-dom';

interface Props {
  to: string;
  value: string;
  scrollOptions?: any;
}

const HeaderLink = ({ to, value, scrollOptions }: Props) => {
  const navigate = useNavigate();

  const scrollerOptions = {
    duration: 1000,
    smooth: 'true',
    offset: -80,
  };

  return (
    <>
      <Button
        onClick={() => {
          navigate('/');
          setTimeout(
            () =>
              scroller.scrollTo(to, { ...scrollerOptions, ...scrollOptions }),
            1
          );
        }}
      >
        {value}
      </Button>
    </>
  );
};

export default HeaderLink;
