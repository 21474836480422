import config from '../utils/config';
import { SpotFile } from '../models/SpotFile';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { FileKind } from 'shared/types/FileKind';
import { withAuthHeader } from 'src/services/auth-headers';

export const spotFilesApi = createApi({
  reducerPath: 'spotFilesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.apiEndpoint}/api/files`,
    prepareHeaders: withAuthHeader,
  }),
  tagTypes: ['File'],
  endpoints: (builder) => ({
    findSpotFiles: builder.query<SpotFile[], string>({
      query: (spotId) => `/spots/${spotId}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ spotId, kind }) => ({
                type: 'File' as const,
                spotId,
                kind,
              })),
              'File',
            ]
          : ['File'],
    }),
    createSpotFile: builder.mutation<
      void,
      { spotId: string; fileKind: FileKind; fileFormData: FormData }
    >({
      query: ({ spotId, fileKind, fileFormData }) => ({
        url: `/spots/${spotId}/${fileKind}`,
        method: 'POST',
        body: fileFormData,
      }),
      invalidatesTags: (result, error, { spotId, fileKind }) => [
        { type: 'File', spotId, fileKind },
      ],
    }),
    deleteSpotFile: builder.mutation<
      void,
      { spotId: string; fileKind: FileKind }
    >({
      query: ({ spotId, fileKind }) => ({
        url: `/spots/${spotId}/${fileKind}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { spotId, fileKind }) => [
        { type: 'File', spotId, fileKind },
      ],
    }),
  }),
});

export const {
  useFindSpotFilesQuery,
  useCreateSpotFileMutation,
  useDeleteSpotFileMutation,
} = spotFilesApi;
